import React, { useEffect, useState } from "react";
import codePulseIcon from "../../../../Assets/Svg/codePulseIcon.svg";
import configureStep1 from "../../../../Assets/Svg/configureStep1.svg";
import sdkStep2 from "../../../../Assets/Svg/sdkStep2.svg";
import monitorStep3 from "../../../../Assets/Svg/monitorStep3.svg";
import analyzeStep4 from "../../../../Assets/Svg/analyzeStep4.svg";
import { useNavigate } from "react-router-dom";
import {
  CODE_PULSE_CONFIGURE,
  CODE_REVIEW,
  DASHBOARD,
} from "../../../../Utils/Constants/RouteConstant";
import axios from "axios";
import Repositories from "../Repositories/components/Repositories";
import { getDataFromLocalStorage } from "../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../../Utils/Constants/localStorageDataModels";
import AnimationLoader from "../../../Common/AnimationLoader";

interface WelcomeScreenProps {
  className?: string;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ className }) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";

  // isPulseActivated - get
  const checkActivePulse = async () => {
    const apiURL = `${
      process.env.REACT_APP_API_URL
    }bucket=pulse&operationName=isPulseActivated&orgName=${encodeURIComponent(
      selectedOrg
    )}`;

    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `${token}`,
        },
      });
      const active = response?.data?.data;
      if (active == "active") {
        setIsActive(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error active repo :", error);
    }
  };

  const setupPulse = async (
    selectedOrg: string,
    token: string
  ): Promise<string | null> => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_API_URL
        }bucket=pulse&operationName=setupPulse&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {},
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.data === "active") {
        return "active";
      } else {
        console.error("Pulse setup response is not active");
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartButton = async () => {
    if (!isActive) {
      const result = await setupPulse(selectedOrg, token);
      if (result === "active") {
        navigate(CODE_PULSE_CONFIGURE);
      }
    } else {
      navigate(CODE_REVIEW);
    }
  };

  useEffect(() => {
    checkActivePulse();
  }, [selectedOrg]);

  return isLoading ? (
    <AnimationLoader />
  ) : isActive ? (
    // - stats and table
    <Repositories />
  ) : (
    <div
      className={`flex flex-col items-center justify-center overflow-hidden p-0 bg-[#080814] w-full min-h-screen ${className} border border-[#1B1B41]`}
    >
      <div className="px-6 py-3">
        <div className="flex justify-center items-center">
          <img
            src={codePulseIcon}
            alt="celebrate"
            className="mb-3 h-[35px] w-[35px]"
          />
        </div>
        <h1 className="text-[28px] font-[600] text-center text-[#E2E2ED]">
          Welcome to CodePulse Setup
          <p className="text-[14px] font-normal text-[#9494A1] mt-1">
            Monitor, Track, and Improve Your Code Health with Real-Time
            Insights.
          </p>
        </h1>
      </div>
      <div className="w-[50%]">
        <h2 className="text-[14px] font-semibold text-[#B2B2C1]">
          Process overview
        </h2>
        <div className="flex-1 justify-center items-center w-[100%]">
          <div className="bg-[#0D0D22] shadow-md rounded-3xl mt-3 py-4 px-7 h-full">
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-4">
                <div className="p-0 rounded-lg">
                  <img
                    src={configureStep1}
                    alt="github logo"
                    className="w-12 h-12"
                  />
                </div>
                <div>
                  <h3 className="font-normal text-[14px] text-[#E2E2ED]">
                    <span className="font-bold">Step 1:</span> Configure Your
                    Setup
                  </h3>
                  <p className="text-[13px] text-[#9494A1] font-normal">
                    Choose your preferred settings and language integration.
                  </p>
                </div>
              </div>
            </div>
            <hr className="w-full border-[#1B1B41] my-4" />

            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-4">
                <div className="p-0 rounded-lg">
                  <img src={sdkStep2} alt="pen" className="w-13 h-13" />
                </div>
                <div>
                  <h3 className="font-normal text-[14px] text-[#E2E2ED]">
                    <span className="font-bold">Step 2:</span> Install CodePulse
                    SDK
                  </h3>
                  <p className="text-[13px] text-[#9494A1] font-normal">
                    Add our SDK to your repository in just a few minutes.
                  </p>
                </div>
              </div>
            </div>
            <hr className="w-full border-[#1B1B41] my-4" />

            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-4">
                <div className="p-0 rounded-lg">
                  <img src={monitorStep3} alt="bug" className="w-12 h-12" />
                </div>
                <div>
                  <h3 className="font-normal text-[14px] text-[#E2E2ED]">
                    <span className="font-bold">Step 3:</span> Monitor and Track
                  </h3>
                  <p className="text-[13px] text-[#9494A1] font-normal">
                    Start receiving real-time insights and error reports.
                  </p>
                </div>
              </div>
            </div>
            <hr className="w-full border-[#1B1B41] my-4" />
            <div className="flex items-center justify-between space-x-4">
              <div className="flex items-center space-x-4">
                <div className="p-0 rounded-lg">
                  <img src={analyzeStep4} alt="bug" className="w-12 h-12" />
                </div>
                <div>
                  <h3 className="font-normal text-[14px] text-[#E2E2ED]">
                    <span className="font-bold">Step 4:</span> Analyse and
                    Improve
                  </h3>
                  <p className="text-[13px] text-[#9494A1] font-normal">
                    Review detailed logs and optimize your code's performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-3">
          <button
            onClick={handleStartButton}
            className="bg-[#875BF8] text-[#FFFFFF] text-[14px] font-normal rounded-[8px] p-2 pl-5 pr-5 mt-3 cursor-pointer"
          >
            Start Setup
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
