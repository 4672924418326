import React from "react";
import SplineChart from "./SplineChart";
import ChartEmpty from "./ChartEmpty";
import Spinner from "../../../Common/Spinner";

interface PRsGraphData {
  date: string;
  reviewed: number;
  merged: number;
  reviewCounts: number;
}

interface ReviewchatProps {
  prsGraph: PRsGraphData[];
  isLoading: boolean;
}

interface ChartData {
  name: string;
  data: number[];
}

const Reviewchart: React.FC<ReviewchatProps> = ({ prsGraph, isLoading }) => {
  const processChartData = (data: PRsGraphData[]) => {
    return {
      labels: data.map((item) => item.date),
      reviewedData: data.map((item) => item.reviewed),
      mergedData: data.map((item) => item.merged),
      reviewCountsData: data.map((item) => item.reviewCounts),
    };
  };

  const { labels, reviewedData, mergedData, reviewCountsData } =
    processChartData(prsGraph);
  // console.log("labels ", labels);
  const chartData1: ChartData[] = [
    {
      name: "Reviewed PRs",
      data: reviewedData,
    },
    {
      name: "Merged PRs",
      data: mergedData,
    },
  ];

  const chartData2: ChartData[] = [
    {
      name: "Review Counts",
      data: reviewCountsData,
    },
  ];

  const chartColors1: string[] = ["#17ACE8", "#C03744"];
  const chartColors2: string[] = ["#5063FF"];
  console.log("Reviewed PRs", reviewedData);
  console.log("review Counts ", reviewCountsData);
  console.log("Merged PRs", mergedData);
  return (
    <div className="flex justify-between gap-5 my-6">
      <div className="w-full bg-[#0D0D22] rounded-xl border-[#1B1B41] border pt-4 px-2">
        {isLoading ? (
          <div className="h-[60vh] flex justify-center  items-center">
            <Spinner />
          </div>
        ) : chartData1[0].data.length > 0 ? (
          <div>
            <div className="flex justify-between items-center text-center px-5">
              <h2 className="font-bold text-[19px] text-[#D9D9E8]">
                Pull requests
              </h2>
              <div className="flex space-x-4 my-2">
                {chartData1.map((item, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: chartColors1[index] }}
                    ></div>
                    <span className="text-sm text-[#9494A1] font-medium">
                      {item.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <SplineChart
              data={chartData1}
              colors={chartColors1}
              stroke={["smooth", "smooth"]}
              labels={labels}
            />
          </div>
        ) : (
          <div className="h-[60vh]">
            <ChartEmpty />
          </div>
        )}
      </div>
      <div className="w-full bg-[#0D0D22] rounded-xl border-[#1B1B41] border pt-4 px-2">
        {isLoading ? (
          <div className="h-[60vh] flex justify-center  items-center">
            <Spinner />
          </div>
        ) : chartData2[0].data.length > 0 ? (
          <div>
            <div className="flex justify-between items-center text-center px-5">
              <h2 className="font-bold text-[19px] text-[#D9D9E8] text-nowrap">
                Review comments
              </h2>
              <div className="flex space-x-4 my-2">
                {chartData2.map((item, index) => (
                  <div key={index} className="flex items-center space-x-2 ">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: chartColors2[index] }}
                    ></div>
                    <span className="text-sm text-[#9494A1] font-medium">
                      {item.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <SplineChart
              data={chartData2}
              colors={chartColors2}
              stroke={["straight"]}
              labels={labels}
            />
          </div>
        ) : (
          <div className="h-[60vh]">
            <ChartEmpty />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviewchart;
