import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaGithub, FaAngleUp, FaAngleDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { IoIosGitBranch } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../../Common/Spinner";
import { CODE_PULSE_CONFIGURE } from "../../../../../Utils/Constants/RouteConstant";
import axios from "axios";
import InstallSDK from "../../ConfigureScreen/Components/InstallSdk";
import ChipList from "./ChipList";
export const projectIdToUuidMap = new Map<string, number>();
type TableRow = {
  name: string;
  branch_name: string;
  update_time: string;
  issue_count: string;
  status: string;
  repoCount: string;
  repo_id: string;
  project_id: number;
  uuid: string;
};
interface Repository {
  name: string;
  id: number;
  // other properties...
}
type SortOrder = "asc" | "desc";
interface RepositoryTableProps {
  tableData: TableRow[];
}

const RepositoryTable: React.FC<RepositoryTableProps> = ({ tableData }) => {
  const selectedOrg = localStorage.getItem("selectedOrg") as string;
  const token = localStorage.getItem("sessionId");
  const [allData, setAllData] = useState<TableRow[]>([]);
  const [displayedData, setDisplayedData] = useState<TableRow[]>([]);
  const [sortColumn, setSortColumn] = useState<keyof TableRow | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const [showDSNDetails, setShowDNSDetails] = useState(false);
  const [url, setUrl] = useState("");
  const [language, setLanguage] = useState<string>("");
  const [repoName, setRepoName] = useState<Repository | null>(null);
  const [branchName, setBranchName] = useState("");
  const [loadingDsn, setLoadingDsn] = useState(false);
  const navigate = useNavigate();
  const lastRowRef = useCallback(
    (node: HTMLTableRowElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const loadMoreData = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      const currentLength = allData.length;
      const nextBatch = tableData.slice(currentLength, currentLength + 10);
      if (nextBatch.length > 0) {
        const newAllData = [...allData, ...nextBatch];
        setAllData(newAllData);
        applyFilteringAndSorting(newAllData);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    }, 1000);
  }, [allData.length, tableData]);

  const handleSort = (column: keyof TableRow) => {
    const order: SortOrder =
      sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
    applyFilteringAndSorting(allData, column, order);
  };

  const applyFilteringAndSorting = (
    data: TableRow[],
    column: keyof TableRow | null = sortColumn,
    order: SortOrder = sortOrder
  ) => {
    let filteredData = data.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    if (column) {
      filteredData = filteredData.sort((a, b) => {
        if (a[column] < b[column]) return order === "asc" ? -1 : 1;
        if (a[column] > b[column]) return order === "asc" ? 1 : -1;
        return 0;
      });
    }

    setDisplayedData(filteredData);
  };

  useEffect(() => {
    const initialData = tableData.slice(0, 10);
    setAllData(initialData);
    applyFilteringAndSorting(initialData);
    setHasMore(tableData.length > 10);
  }, [tableData]);

  useEffect(() => {
    applyFilteringAndSorting(tableData);
  }, [searchQuery, allData]);

  const handleRowClick = ({
    project_id,
    issue_count,
    secureId,
    repo_name,
    branch_name,
  }: {
    project_id: number;
    issue_count: number;
    secureId: string;
    branch_name: string;
    repo_name: string;
  }) => {
    if (issue_count === 0 || issue_count === null) {
      setRepoName({ id: project_id, name: repo_name });
      setBranchName(branch_name);
      setShowDNSDetails(!showDSNDetails);
      fetchDSNDetails(project_id);
    } else {
      navigate(`/pulse/${secureId}`, { state: { project_id } });
    }
  };
  const handleStartButton = () => {
    navigate(CODE_PULSE_CONFIGURE);
  };
  const fetchDSNDetails = async (projectID: number): Promise<void> => {
    try {
      const apiURL = `${
        process.env.REACT_APP_API_URL
      }bucket=pulse&operationName=getConnectId&orgName=${encodeURIComponent(
        selectedOrg
      )}&project_id=${projectID}`;
      // const token = getSessionID();
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setLoadingDsn(true);
      const { data } = response.data;
      setUrl(data?.dsn);
      setLanguage(
        data?.platform?.[0].toUpperCase() + data?.platform?.slice(1) || ""
      );
      console.log("data reponse", data?.platform);
    } catch (error) {
      setLoadingDsn(true);
      console.error("Error fetching data:", error);
    }
  };
  // const fetchDSNDetails = async (projectID: number): Promise<void> => {
  //   try {
  //     const apiURL = `${
  //       process.env.REACT_APP_API_URL
  //     }bucket=pulse&operationName=getConnectId&orgName=${encodeURIComponent(
  //       selectedOrg
  //     )}&project_id=${projectID}`;
  //     const response = await axios.get(apiURL, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     });
  //     setLoadingDsn(false);
  //     const { data } = response.data;
  //     setUrl(data?.dsn);
  //     setLanguage(
  //       data?.platform?.[0].toUpperCase() + data?.platform?.slice(1) || ""
  //     );
  //   } catch (error) {
  //     setLoadingDsn(false);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const getSortIcons = (column: keyof TableRow) => (
    <div className="inline-flex flex-col ml-1 -space-y-1 ">
      <FaAngleUp
        className={`${
          sortColumn === column && sortOrder === "asc" ? "" : "text-gray-500"
        }`}
      />
      <FaAngleDown
        className={`${
          sortColumn === column && sortOrder === "desc" ? "" : "text-gray-500"
        }`}
      />
    </div>
  );
  const timeAgo = (timestamp: number): string => {
    const now = new Date().getTime();
    const then = new Date(timestamp * 1000).getTime();
    const diff = now - then;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) {
      return `${days} days ago`;
    } else if (days === 1) {
      return `1 day ago`;
    } else if (hours > 1) {
      return `${hours} hours ago`;
    } else if (hours === 1) {
      return `1 hour ago`;
    } else if (minutes > 1) {
      return `${minutes} minutes ago`;
    } else if (minutes === 1) {
      return `1 minute ago`;
    } else {
      return `Just now`;
    }
  };

  const handleBack = () => {
    setLoadingDsn(false);
    setShowDNSDetails(!showDSNDetails);
    // navigate("/pulse");
  };
  console.log("show dsn ", loadingDsn);
  const repoNameString =
    typeof repoName === "string" ? repoName : repoName?.name || "";

  return (
    <div className="h-screen overflow-scroll no-scrollbar flex flex-col">
      {showDSNDetails ? (
        !loadingDsn ? (
          <div className="w-full h-screen flex justify-center items-center ">
            <Spinner />
          </div>
        ) : (
          <div className="flex  justify-center items-center w-full h-screen bg-[#080814] ">
            <InstallSDK
              language={language}
              dsnURL={url}
              repoName={repoName?.name || ""}
              branchName={branchName}
              setShowDNSDetails={setShowDNSDetails}
              onBack={handleBack}
            />
          </div>
        )
      ) : (
        <>
          <ChipList />
          <div className="sticky top-0  z-20">
            <div className="flex items-center justify-between py-4 px-4 text-[#D1D1E3]">
              <h1 className="text-lg font-medium">
                Repository ({tableData?.length})
              </h1>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <CiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#7D7D89]" />
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search for repositories..."
                    className="pl-8 px-10 py-2 bg-[#131330] border border-[#2E2E60] text-[#B2B2C1] text-sm rounded-lg focus:outline-none focus:border-[#2E2E60]"
                  />
                </div>
                <button
                  className="bg-[#875BF8] text-[14px] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
                  onClick={handleStartButton}
                >
                  <FaPlus className="text-white" />
                  <span>Add Repositories</span>
                </button>
              </div>
            </div>
            <div className=" max-h-[85vh]  no-scrollbar overflow-scroll rounded-xl shadow-md  bg-[#0D0D22] border border-[#1B1B41]">
              <table className="w-full">
                <thead className="bg-[#0D0D22] border-b border-[#1C1C1F] sticky top-0 z-10 rounded-lg">
                  <tr>
                    <th
                      className="py-4 px-4 text-left text-[14px] font-medium text-[#9999A8] cursor-pointer"
                      onClick={() => handleSort("name")}
                    >
                      <span className="flex items-center">
                        Repository Name {getSortIcons("name")}
                      </span>
                    </th>

                    <th
                      className="py-3 px-4 text-left text-[14px] font-medium text-[#9999A8] cursor-pointer"
                      onClick={() => handleSort("update_time")}
                    >
                      <span className="flex items-center">
                        Last Updated {getSortIcons("update_time")}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 text-left text-[14px] font-medium text-[#9999A8] cursor-pointer"
                      onClick={() => handleSort("branch_name")}
                    >
                      <span className="flex items-center">
                        Branch {getSortIcons("branch_name")}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 text-left text-[14px] font-medium text-[#9999A8] cursor-pointer"
                      onClick={() => handleSort("issue_count")}
                    >
                      <span className="flex items-center">
                        Issue Count {getSortIcons("issue_count")}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 text-left text-[14px] font-medium text-[#9999A8] cursor-pointer"
                      onClick={() => handleSort("status")}
                    >
                      <span className="flex items-center">
                        Status {getSortIcons("status")}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#0D0D22] text-[#D1D1E3]">
                  {displayedData.map((row, index) => (
                    <tr
                      key={row.uuid}
                      ref={
                        index === displayedData.length - 1 ? lastRowRef : null
                      }
                      onClick={() =>
                        handleRowClick({
                          project_id: row.project_id,
                          issue_count: parseInt(row.issue_count, 10),
                          secureId: row.uuid,
                          branch_name: row.branch_name,
                          repo_name: row.name,
                        })
                      }
                      className="border-b border-[#313145] hover:bg-[#1E1E4A] cursor-pointer"
                    >
                      <td className="py-3 px-4 flex items-center text-[#9494A1]">
                        <FaGithub className="mr-2 text-[9494A1]" /> {row.name}
                        {/* <IoIosGitBranch className="mx-1" /> {row.repoCount} */}
                      </td>

                      <td className="py-3 px-4 text-[#9494A1]">
                        {timeAgo(Number(row.update_time))}
                      </td>
                      <td className="py-3 px-4 text-[#9494A1]">
                        {row.branch_name.length > 7
                          ? `${row.branch_name.substring(0, 7)}...`
                          : row.branch_name}
                      </td>

                      <td className="py-3 px-4 text-[#9494A1]">
                        {row?.issue_count || 0}
                      </td>
                      <td className="py-3 px-4 text-[#9494A1]">
                        {row?.status ? "Tracking" : "Not Tracking"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {loading && (
              <div className="flex justify-center items-center py-4">
                <Spinner w={3} h={3} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default RepositoryTable;
