import React, { useState } from "react";
import NoChat from "./NoChat";
import { FiSearch } from "react-icons/fi";
import { FaPlus } from "react-icons/fa6";
import { splitAndReduceText } from "../../../../Utils/Services/Interceptor";

interface Thread {
  threadid: string;
  title: string;
}

interface ChatThreadListProps {
  threads: Thread[];
  onSelectThread: (threadId: string) => void;
  selectedThreadId: string | null;
  onStartNew: () => void;
  isCreatingNew: boolean;
}

const ChatThreadList: React.FC<ChatThreadListProps> = ({
  threads,
  onSelectThread,
  selectedThreadId,
  onStartNew,
  isCreatingNew,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const cleanTitle = (str: string | null): string => {
    if (!str) return "";
    return str.replace(/\*\*/g, "").replace(/"/g, "");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredThreads = threads.filter((thread) =>
    cleanTitle(thread.title).toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-[25%] bg-[#0D0D22] py-4 overflow-y-auto border border-[#1B1B41] flex flex-col justify-between rounded-l-lg">
      <div>
        <h2 className="text-[#D1D1E3] text-[18px] mx-4 text-start">
          Code Assist{" "}
        </h2>
      </div>
      {/* Search Input */}
      <div className="w-auto  my-2 flex items-center mx-1 gap-2">
        {/* Input Field with Icon */}
        <div className="flex items-center bg-[#131330] rounded-lg border border-[#1B1B41] w-full">
          <FiSearch className="text-[#B2B2C1] text-[18px] mx-2" />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearch}
            className="bg-transparent outline-none text-[#C0C4CE] w-full py-1"
          />
        </div>

        {/* Button Next to Input */}
        <button
          onClick={onStartNew}
          className="bg-[#875BF8] text-white p-2 rounded-md"
          disabled={isCreatingNew}
        >
          <FaPlus />
        </button>
      </div>

      {/* Threads List */}
      {filteredThreads.length > 0 ? (
        <div className="space-y-2 h-screen overflow-y-auto codepluse-scrollbar border-t border-[#1B1B41] w-full pt-2">
          {filteredThreads.map((thread) => (
            <div
              key={thread.threadid}
              onClick={() => onSelectThread(thread.threadid)}
              className={`p-2 mx-1 rounded-lg cursor-pointer border border-[#1B1B41] text-[13px] ${
                selectedThreadId === thread.threadid
                  ? "bg-[#1B1B41] text-white"
                  : "bg-[#131330] text-[#D9D9E8]"
              }`}
            >
              {cleanTitle(thread?.title)}
            </div>
          ))}
        </div>
      ) : (
        <div className="w-full h-screen flex flex-col justify-center items-center">
          <NoChat />
          <div className="w-full px-12 mt-2">
            <button
              onClick={onStartNew}
              className="w-full bg-[#875BF8] text-white py-1 rounded-lg"
              disabled={isCreatingNew}
            >
              {"Start New Chat"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatThreadList;
