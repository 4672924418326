import Lottie from "lottie-react";
import redirectLogoAnimation from "../../Assets/redirectloadingAnimation.json";
const AnimationLoader = () => {
  return (
    <div className="flex flex-col items-center justify-between h-screen bg-[#080814]">
      <div className="flex flex-col items-center justify-center flex-grow">
        <Lottie
          animationData={redirectLogoAnimation}
          loop={true}
          className="w-32 h-32"
        />
        <h1 className="mt-4 text-[18px] text-[#FFFFFF] font-bold">
          Please wait...
        </h1>
        <p className="text-[16px] text-[#8B8B99] font-normal mt-1">
          It may take a moment.
        </p>
      </div>
    </div>
  );
};

export default AnimationLoader;
