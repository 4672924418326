import React, { useState, useEffect } from "react";
import ReviewChips from "./components/ReviewChips";
import Reviewchart from "./components/Reviewchart";
import Header from "./components/Header";
import axios from "axios";
import { getDataFromLocalStorage } from "../../../Utils/globalUtilities";
import { toast } from "react-toastify";
import comingSoon from "../../../Assets/Svg/comingSoonCodereview.svg";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../Utils/Constants/localStorageDataModels";
import AnimationLoader from "../../Common/AnimationLoader";
import ComingSoon from "./components/ComingSoon";
interface ReviewStats {
  topCards: {
    prs_reviewed: number;
    review_count: number;
    files_reviewed: number;
    suggestions_accepted: number;
  };
  prsGraph: Array<{
    date: string;
    reviewed: number;
    merged: number;
    reviewCounts: number;
  }>;
}

const CodeReview: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reviewStats, setReviewStats] = useState<ReviewStats | null>(null);
  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";
  const formatDateForUrl = (date: Date): string => {
    return date.toISOString().split("T")[0];
  };
  const fetchReviewStats = async (
    rangeType: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    setIsLoading(true);
    try {
      let url = `${API_URL}bucket=review&operationName=reviewStats&carrier=github&orgName=${encodeURIComponent(
        selectedOrg
      )}&rangeType=${rangeType}`;

      if (rangeType === "custom" && startDate && endDate) {
        url += `&start=${formatDateForUrl(startDate)}&end=${formatDateForUrl(
          endDate
        )}`;
      }

      const response = await axios.get<{ message: string; data: ReviewStats }>(
        url,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // console.log("stats from review ", response.data.data);
      setReviewStats(response.data.data);
    } catch (err) {
      toast.error("Failed to fetch review stats");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRangeChange = (
    rangeType: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    fetchReviewStats(rangeType, startDate, endDate);
  };

  useEffect(() => {
    fetchReviewStats("last_7_days");
  }, []);

  return (
    <div
      className={`w-full h-full overflow-scroll no-scrollbar flex flex-col bg-[#080814] px-5 py-4`}
    >
      {reviewStats ? (
        <>
          <Header onRangeChange={handleRangeChange} />
          <ReviewChips topCards={reviewStats?.topCards} />
          <Reviewchart prsGraph={reviewStats?.prsGraph} isLoading={isLoading} />
          {/* <StackedChart /> */}
          {/* <ComingSoon /> */}
          <div className="w-[100%] flex justify-center items-center ">
            <img
              src={comingSoon}
              alt="comingSoon"
              className="w-full h-full object-cover"
            />
          </div>
        </>
      ) : (
        <AnimationLoader />
      )}
    </div>
  );
};

export default CodeReview;
