import axios from "axios";
import React, { useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Spinner from "../../../../Common/Spinner";
import { splitAndReduceText } from "../../../../../Utils/Services/Interceptor";

interface ShutterProps {
  repoName: string;
  setOpenLog: (open: boolean) => void;
  logData: any;
  loading: Boolean;
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZoneName: "short",
  });
};

const Shutter: React.FC<ShutterProps> = ({ setOpenLog, logData, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  // console.log("shutter : ", logData);
  return (
    <div
      className={`bg-[#131330] transition-all duration-300 ${
        isOpen ? "h-auto" : "h-20"
      } flex flex-col relative rounded-t-xl  `}
    >
      <div className="p-3 ">
        <div className="flex pb-3 justify-between items-center border-[#2E2E60] border-b">
          <h2 className="text-[14px]  text-white ">
            {splitAndReduceText(logData?.message, 10)}
          </h2>

          <button
            className={`bg-[#131330] text-[#875BF8] text-[13px] px-4 py-2 border-[#875BF8] border rounded-lg ${
              !logData ? "cursor-not-allowed opacity-50" : "cursor-pointer"
            }`}
            disabled={!logData}
            onClick={() => {
              if (logData) {
                setOpenLog(true);
              }
            }}
          >
            View log details
          </button>
        </div>
        {isOpen && (
          <div className="mt-4 text-white overflow-y-auto px-2 border-[#2E2E60] border-b">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="flex justify-start gap-5">
                  <div>
                    <h1 className="text-[#9494A1] text-[12px]">Type Error</h1>

                    <h1 className="text-[#B2B2C1] text-[14px]">
                      {" "}
                      Cannot read properties of undefined (reading 'then')
                    </h1>
                  </div>
                  <div>
                    <h1 className="text-[#875BF8] text-[12px]">
                      {logData?.id}
                    </h1>

                    <h1 className="text-[#B2B2C1] text-[14px]">
                      {formatDate(logData?.dateCreated)}
                    </h1>
                  </div>
                </div>
                <div className="flex flex-col  pb-3  mt-3 mb-2">
                  <div className="flex  justify-between items-center">
                    <div className="pb-5">
                      <h1 className="text-[#D1D1E3]  text-[13px]">IP</h1>
                      <p className="text-[#B2B2C1] text-[14px] font-normal">
                        {logData?.user?.ip_address}
                      </p>
                    </div>
                    <div className="pb-5">
                      <h1 className="text-[#D1D1E3]  text-[13px]">
                        {logData?.platform}
                      </h1>
                      <p className="text-[#B2B2C1] text-[14px] font-normal">
                        Version: {logData?.contexts?.runtime?.version}
                      </p>
                    </div>
                    <div className="flex flex-col pb-5">
                      <h1 className="text-[#D1D1E3]  text-[13px]">Device</h1>
                      <p className="text-[#B2B2C1] text-[14px] font-normal">
                        Unknown Device
                      </p>
                    </div>
                    <div>
                      <h1 className="text-[#D1D1E3] text-[13px]">Last Seen</h1>
                      <h1 className="text-[#B2B2C1] text-[11px]  font-normal">
                        {formatDate(logData?.dateReceived)}
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* <div className="flex justify-between items-center my-5">
              {dummydata.map((data) => (
                <div>
                  <h1 className="text-[#D1D1E3] text-[14px]">{data?.title}</h1>
                  <h1 className="text-[#B2B2C1] text-[14px]">
                    {data?.subtitile}
                  </h1>
                  <h1 className="text-[#B2B2C1] text-[10px]">
                    {data?.subtitle2}
                  </h1>
                </div>
              ))}
            </div> */}
          </div>
        )}
      </div>
      <button
        className="absolute bottom-0 right-0 w-full mb-[-7px] text-white px-2 flex justify-center items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <FaChevronUp
            className="bg-[#2E2E60] p-1  w-7 h-7 rounded-lg text-white"
            style={{ zIndex: 20 }}
          />
        ) : (
          <FaChevronDown
            className="bg-[#2E2E60] p-1  w-7 h-7 rounded-lg text-white"
            style={{ zIndex: 20 }}
          />
        )}
      </button>
    </div>
  );
};

export default Shutter;
