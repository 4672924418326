import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SideBar from "../../Components/Common/SideBar";
import {
  removeDataFromLocalStorage,
} from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";
import Modal from "../../Components/Common/Modal";
import SetupModal from "../../Components/Common/SetupModal";
import "highlight.js/styles/atom-one-dark.css";


const Layout = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isFullSidebar, setIsFullSidebar] = useState(true);
  const [isLogoutConfirmed, setIsLogoutConfirmed] = useState(false);

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  const handleConfirmLogout = () => {
    // Remove token or other data from local storage
    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.ACCESS_TOKEN);
    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.USER_DATA);
    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG);
    removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID);
    setIsModalOpen(false);
    navigate("/");
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <SideBar
        className={`h-full relative bg-[#080814]  ${isFullSidebar ? "w-[20%]" : "w-[6%]"}`}
        onLogout={handleLogout}
        isFullSidebar={isFullSidebar}
        setIsFullSidebar={setIsFullSidebar}
      />
      <div
        className={`justify-center items-center      ${isFullSidebar ? "w-[80%]" : "w-[94%]"
          }
        `}
      >
        <Outlet />
      </div>

      {isModalOpen &&
        <Modal
          isOpen={isModalOpen}
          onClose={handleClose}
          onConfirm={handleConfirmLogout}
          heading="Logout?"
          content="Are you sure you want to logout?"
          primaryButton="Close"
          secondaryButton="Logout"
        />
      }

      {/* <SetupModal
        isOpen={isModalOpen}
        onClose={handleClose}
        heading="Setup Complete!"
        content="Your repositories are now linked to CodePulse. Monitoring will start immediately."
        buttonText="View logs"
      /> */}
    </div>
  );
};

export default Layout;
