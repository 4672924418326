import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

import LogData from "./LogData";
import appIcon from "../../../../../Assets/Svg/applogicon.svg";
import osIcon from "../../../../../Assets/Svg/oslogicon.svg";
import codeIcon from "../../../../../Assets/Svg/codelogicon.svg";
import timeIcon from "../../../../../Assets/Svg/timelogicon.svg";
import cultureIcon from "../../../../../Assets/Svg/culturelogicon.svg";
import deviceIcon from "../../../../../Assets/Svg/devicelogicon.svg";
import { GoArrowLeft } from "react-icons/go";
import axios from "axios";
// import { getSessionID } from "../../../../../UserContext";
import Spinner from "../../../../Common/Spinner";
import { formatDate } from "./Shutter";
import { splitAndReduceText } from "../../../../../Utils/Services/Interceptor";
import MarkdownPreview from '@uiw/react-markdown-preview';
import MarkdownConverter from "../../../../Common/MarkdownConverter";

interface LogModalProps {
  setOpenLog: (open: boolean) => void;
  projectId?: string;
  issueId?: string;
  orgName: string;
  logData: any;
  loading: Boolean;
}
const LogModal: React.FC<LogModalProps> = ({
  setOpenLog,
  logData,
  loading,
}) => {

  const logData1 = [
    {
      title: "App",
      Subtitle1: "app_memory",
      numbervalue1: logData?.contexts?.app?.app_memory || "nadee ",
      Subtitle2: "app_start_time",
      numbervalue2: logData?.contexts?.app?.app_start_time,
      img: appIcon,
    },
    {
      title: "Culture",
      Subtitle1: "locale",
      numbervalue1: logData?.contexts?.culture?.locale,
      Subtitle2: "timezone",
      numbervalue2: logData?.contexts?.culture?.timezone,
      img: cultureIcon,
    },
  ];

  const logData2 = [
    {
      title: "Runtime",
      Subtitle1: "name",
      numbervalue1: logData?.contexts?.runtime?.name,
      Subtitle2: "version",
      numbervalue2: logData?.contexts?.runtime?.version,
      img: timeIcon,
    },
    {
      title: "Trace",
      Subtitle1: "span_id",
      numbervalue1: logData?.contexts?.trace?.span_id,
      Subtitle2: "trace_id",
      numbervalue2: logData?.contexts?.trace?.trace_id,
      img: codeIcon,
    },
  ];

  // In the LogModal component:

  const logData3 = [
    {
      title: "Device",
      img: deviceIcon,
      data: Object.entries(logData?.contexts?.device ?? {}).map(
        ([subtitle, value]) => ({
          subtitle,
          value:
            typeof value === "object" ? JSON.stringify(value) : String(value),
        })
      ),
      compnent: 2,
    },
    {
      title: "OS",
      img: osIcon,
      data: Object.entries(logData?.contexts?.os ?? {}).map(
        ([subtitle, value]) => ({
          subtitle,
          value:
            typeof value === "object" ? JSON.stringify(value) : String(value),
        })
      ),
      compnent: 2,
    },
  ];

  function calculateTimeAgo(date: string | Date): string {
    const currentDate = new Date();
    const eventDate = new Date(date);

    // Calculate the difference in milliseconds
    const differenceInMs = currentDate.getTime() - eventDate.getTime();

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return "Today";
    }

    return `${differenceInDays} ${differenceInDays === 1 ? "day" : "days"} ago`;
  }

  return (
    <div
      className={`  bg-[#131330] h-full flex flex-col  relative  w-[100%] px-5 overflow-auto no-scrollbar `}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <button onClick={() => setOpenLog(false)} className="mt-4 p-2  ">
            <GoArrowLeft className=" text-[#B2B2C1] text-xl" />
          </button>
          <div className="w-full flex justify-between items-start  ">
            <div className="w-[50%]">
              <div className="flex flex-col  pb-3 border-b border-[#2E2E60]">
                <h1 className="text-[#D1D1E3] font-bold text-[18px]">
                  {splitAndReduceText(logData?.message, 10)}
                </h1>
                <h1 className="text-[#875BF8] text-[14px] mb-1">
                  {logData?.id}
                </h1>
                <p className="text-[#B2B2C1] text-[14px] font-normal">
                  {formatDate(logData?.dateCreated)}
                </p>
              </div>
              <div className="flex flex-col  pb-3  my-2">
                <div className="flex  justify-between items-center">
                  <div className="">
                    <h1 className="text-[#D1D1E3]  text-[13px]">IP</h1>
                    <p className="text-[#B2B2C1] text-[14px] font-normal">
                      {logData?.user?.ip_address}
                    </p>
                  </div>
                  <div>
                    <h1 className="text-[#D1D1E3]  text-[13px]">
                      {logData?.platform}
                    </h1>
                    <p className="text-[#B2B2C1] text-[14px] font-normal">
                      Version: {logData?.contexts?.runtime?.version}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  pb-3 border-b border-[#2E2E60] my-2">
                <h1 className="text-[#D1D1E3]  text-[13px]">Device</h1>
                <p className="text-[#B2B2C1] text-[14px] font-normal">
                  Unknown Device
                </p>
              </div>
            </div>
            <div className="flex flex-col w-[48%] items-start pl-12">
              <div>
                <h1 className="text-[#D1D1E3] text-[14px]">Events</h1>
                <h1 className="text-[#D5D5DC] text-[14px] font-normal">
                  {logData?.eventID}
                </h1>
              </div>
              <div className="my-5">
                <h1 className="text-[#D1D1E3] text-[13px]">First Seen</h1>
                <h1 className="text-[#B2B2C1] font-semibold text-[14px]">
                  {calculateTimeAgo(logData?.dateCreated)}
                </h1>
                <h1 className="text-[#B2B2C1] text-[11px] font-normal">
                  {formatDate(logData?.dateCreated)}
                </h1>
              </div>
              <div>
                <h1 className="text-[#D1D1E3] text-[13px]">Last Seen</h1>
                <h1 className="text-[#B2B2C1] font-semibold text-[14px]">
                  {calculateTimeAgo(logData?.dateReceived)}
                </h1>
                <h1 className="text-[#B2B2C1] text-[11px]  font-normal">
                  {formatDate(logData?.dateReceived)}
                </h1>
              </div>
            </div>
          </div>
          <div>
            <h1 className="text-[#D1D1E3] font-bold text-[16px]">Device</h1>
            <p className="text-[#B2B2C1] font-normal text-nowrap text-[13px]">
              {logData?.message}
            </p>
            <p className="text-[#B2B2C1] font-normal  text-[14px]">
              {logData?.culprit}
            </p>
            <div className="mt-2">
              <MarkdownConverter contextCode={logData?.entries[0]?.data?.values[0]?.stacktrace?.frames} />
            </div>
            <div className="my-2">
              <p className="text-[#B2B2C1] font-normal text-nowrap text-[13px]">
                {logData?.metadata?.filename}
              </p>
            </div>

            <div className="flex flex-wrap justify-between items-center gap-1">
              {logData1.map((data, index) => (
                <LogData key={index} {...data} compnent={1} />
              ))}
            </div>
            <div className="flex flex-wrap justify-between items-center gap-1 my-3">
              {logData3.map((data, index) => (
                <LogData key={index} {...data} />
              ))}
            </div>
            <div className="flex flex-wrap justify-between items-center gap-1 my-3">
              {logData2.map((data, index) => (
                <LogData key={index} {...data} compnent={1} />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LogModal;
