import React, { useState } from "react";
import axios from "axios";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa"; // Icons from react-icons
// import { getSessionID, useUser } from "../../../../../UserContext";
import { OrgType } from "../../ConfigureScreen/types";
import { getDataFromLocalStorage } from "../../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../../../Utils/Constants/localStorageDataModels";

interface IssueToggleProps {
  projectId: number;
  issueId: number;
  initialStatus: string;
}

const IssueToggle: React.FC<IssueToggleProps> = ({
  projectId,
  issueId,
  initialStatus,
}) => {
  const [status, setStatus] = useState(initialStatus);
  // const { userData } = useUser();
  const API_URL = process.env.REACT_APP_API_URL;
  const selectedOrg: string = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) || "No organization";

  const getAuthHeaders = () => ({
    Authorization: `${getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID)}`,
  });

  const handleToggleStatus = async () => {
    const newStatus = status === "resolved" ? "unresolved" : "resolved";

    try {
      const response = await axios.post(
        `${API_URL}bucket=pulse&operationName=updateIssue&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          project_id: projectId,
          issue_id: issueId,
          status: newStatus,
        },
        { headers: getAuthHeaders() }
      );

      if (response.status === 200) {
        setStatus(newStatus);
      }
    } catch (error) {
      console.error("Error updating issue status:", error);
    }
  };

  return (
    <div onClick={handleToggleStatus} style={{ cursor: "pointer" }}>
      {status === "resolved" ? (
        <FaCheckCircle size={14} color="green" /> // Filled icon for resolved
      ) : (
        <FaRegCircle size={14} color="gray" /> // Unfilled icon for unresolved
      )}
    </div>
  );
};

export default IssueToggle;
