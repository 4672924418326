import React from "react";

interface ActionCardProps {
  imageSrc: string;
  title: string;
  description: string;
  onClick?: () => void;
  altText?: string;
  iconImg?: string;
}

const ActionCard: React.FC<ActionCardProps> = ({
  imageSrc,
  title,
  description,
  onClick,
  altText = "",
  iconImg = "",
}) => {
  return (
    <div className="flex items-center justify-between space-x-4">
      {/* Left section for the image */}
      <div className="flex-shrink-0 w-[45px] h-[44px]">
        <img
          src={imageSrc}
          alt={altText}
          className="w-full h-full object-contain"
        />
      </div>

      {/* Right section for the text */}
      <div className="flex-grow">
        <h3 className="font-[700] text-[14px] text-[#D1D1E3]">{title}</h3>
        <p className="text-[13px] text-[#8B8B99]">{description}</p>
      </div>

      {/* Optional icon on the right side */}
      {iconImg && (
        <div className="flex-shrink-0">
          <img
            src={iconImg}
            alt="right arrow"
            className="cursor-pointer"
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default ActionCard;
