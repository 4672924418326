import React from "react";
import Logo from "../../Assets/Svg/Logo.svg";
import Facebook from "../../Assets/Svg/facebook.svg";
import LinkedIn from "../../Assets/Svg/linkedin.svg";
import Twitter from "../../Assets/Svg/twitter.svg";

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <div className="flex flex-col items-center text-center text-white p-4 max-w-[1200px] mx-auto">
      <div className="flex flex-col sm:flex-row items-center justify-between w-full">
        <div className="flex items-center mb-4 sm:mb-0 cursor-pointer" onClick={scrollToTop}>
          <img
            src={Logo}
            alt="DrCode.ai"
            className="h-32 w-32"
            style={{ verticalAlign: 'middle' }} // Add inline style for fine-tuning
          />
          {/*<span className="text-2xl font-bold text-white font-spaceGrotesk">DrCode AI</span>*/}
        </div>
        <p className="text-sm text-gray-400 mb-4 sm:mb-0">© 2024 DrCode AI. All rights reserved.</p>
        {/* <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
          <p className="text-[#62627C] hover:text-purple-300 font-medium transition duration-300 cursor-pointer">
            Pricing
          </p>
          <p className="text-[#62627C] hover:text-purple-300 font-medium transition duration-300 cursor-pointer">
            Blog
          </p>
          <p className="text-[#62627C] hover:text-purple-300 font-medium transition duration-300 cursor-pointer">
            About us
          </p>
        </div> */}
      </div>
      <hr className="w-full border-gray-700 my-4" />
      {/* <div className="flex flex-col sm:flex-row items-center justify-between w-full">
        <p className="text-sm text-gray-400 mb-4 sm:mb-0">© 2024 DrCode AI. All rights reserved.</p>
        <div className="flex space-x-4">
          <a href="#" aria-label="Facebook">
            <img src={Facebook} alt="Facebook" className="h-6 w-6" />
          </a>
          <a href="#" aria-label="Twitter">
            <img src={Twitter} alt="Twitter" className="h-6 w-6" />
          </a>
          <a href="#" aria-label="LinkedIn">
            <img src={LinkedIn} alt="LinkedIn" className="h-6 w-6" />
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;