import React from 'react';
import NetworkTag from "../../Assets/Svg/NetworkTag.svg";
import ShieldStar from "../../Assets/Svg/ShieldStar.svg";
import CodeFile from "../../Assets/Svg/CodeFile.svg";

const DescriptionSection = () => {
  return (
    <div className="text-white py-16 px-6 lg:px-20">
      <div className="flex flex-col lg:flex-row justify-between items-start">
        <div className="lg:w-1/2 w-full">
          <p className="text-[20px] text-[#875BF8] font-semibold">You're in safe hands!</p>
          <h1 className="text-4xl lg:text-6xl font-bold mt-2 leading-tight text-[#D1D1E3]">
            We prioritize <br /> security, privacy, <br /> and compliance.
          </h1>
          {/*<p className="mt-4 text-[#8B8B99]">
            Efficiency redefined: Save time and accelerate your <br/> tasks with our automation solution.
          </p>*/}
        </div>
        <div className="mt-10 lg:mt-0 lg:w-1/2 flex flex-col space-y-8">
          <div className="flex items-start">
            <div className="mr-4 text-purple-400 text-3xl flex-none">
              <img className="h-13 w-13" src={NetworkTag} alt="network" />
            </div>
            <div>
              <h3 className="text-lg font-bold text-[#D1D1E3]">Reviews only the essential code</h3>
              <p className="text-[#8B8B99] mt-1 font-normal">
                Ensures that only the necessary parts of your code are accessed, safeguarding sensitive information and maintaining privacy.
              </p>
            </div>
          </div>
          <div className="flex items-start">
            <div className="mr-4 text-purple-400 text-3xl flex-none">
              <img className="h-13 w-13" src={ShieldStar} alt="shield" />
            </div>
            <div>
              <h3 className="text-lg font-bold text-[#D1D1E3]">SSL encrypted data</h3>
              <p className="text-[#8B8B99] mt-1 font-normal">
                Secures data transmission with SSL encryption, protecting from unauthorized access and ensuring a safe and secure connection at all times.
              </p>
            </div>
          </div>
          {/*<div className="flex items-start">
            <div className="mr-4 text-purple-400 flex-none">
              <img className="h-13 w-13" src={CodeFile} alt="code" />
            </div>
            <div>
              <h3 className="text-lg font-bold text-[#D1D1E3]">SOC2 Type II certified</h3>
              <p className="text-[#8B8B99] mt-1 font-normal">
                Maecenas rhoncus tellus massa, non dapibus mi rutrum nec. Pellentesque placerat ornare orci, non iaculis metus pretium non.
                Sed pulvinar risus vel tortor rutrum, sed laoreet lorem.
              </p>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default DescriptionSection;