import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getDataFromLocalStorage } from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";
// import { getSessionID } from "../../UserContext";

const API_URL = process.env.REACT_APP_API_URL;
const ContactUs = () => {
  const [email, setEmail] = useState("");
  const getAuthHeaders = () => ({
    Authorization: `${getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID)}`,
  });
  const sendEmailHandler = async () => {
    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}bucket=auth&operationName=contactus`,
        { email },
        { headers: { ...getAuthHeaders(), "Content-Type": "application/json" } }
      );

      // Assuming the response has a data.message field
      const responseMessage = response.data.message;

      // Display the message as a toast notification
      if (responseMessage == "ok") {
        toast.success("Email sent Successfully");
      } else {
        toast.error(responseMessage);
      }

      setEmail("");
    } catch (error) {
      console.error("Error sending contact request:", error);
      toast.error("An error occurred while sending your request.");
    }
  };

  return (
    <div className="pl-6 pr-6 ">
      <div className="bg-gradient-to-bl from-[#A380FF] to-[#290781] text-white mt-9 mb-7 rounded-2xl p-8 md:p-12 flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0 shadow-lg relative overflow-hidden">
        <div className="absolute inset-0 opacity-20 bg-texture-pattern pointer-events-none"></div>
        <div className="relative z-10">
          <h2 className="text-2xl lg:text-5xl md:5xl font-bold font-inter">
            We're not just selling a tool,
            <br className="hidden md:block" /> we're selling time!
          </h2>
          <p className="mt-2 text-sm md:text-base text-[#8B8B99]">
            Why always prioritise between time and quality when with DrCode you
            can get both.
          </p>
        </div>
        <div className="relative w-full max-w-xl mx-auto md:mx-auto flex items-center bg-[#0A0A1F] p-1 rounded-lg">
          <input
            type="email"
            placeholder="Enter your work email"
            className="p-4 w-full rounded-lg focus:outline-none bg-[#0A0A1F] text-white placeholder-[#62627C]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            onClick={sendEmailHandler}
            className="absolute right-1 top-1 bottom-1 bg-gradient-to-b from-[#A380FF] via-[#9975F4] to-[#290781] text-white px-4 py-2 md:px-7 md:py-2 rounded-lg hover:from-[#9A34DF] hover:to-[#553BD4] focus:outline-none"
          >
            Enquire Now!
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
