import React from "react";

interface SpinnerProps {
  w?: number; // Optional width prop
  h?: number; // Optional height prop
}

const Spinner: React.FC<SpinnerProps> = ({ w = 4, h = 4 }) => {
  return (
    <div className="flex justify-center items-center py-4">
      <div className="relative">
        <div
          className="border-4 border-t-4 border-[#1B1B41] border-t-[#875BF8] rounded-full animate-spin"
          style={{ width: `${w}rem`, height: `${h}rem` }}
        ></div>
      </div>
    </div>
  );
};

export default Spinner;
