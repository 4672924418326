import React, { useEffect, useRef } from "react";

type CardProps = {
  image: string;
  name: string;
  description: string;
  designation: string;
  title: string;
};

const Card: React.FC<CardProps> = ({
  image,
  name,
  description,
  title,
  designation,
}) => {
  const isNameLong = name.length > 10;
  const displayName = isNameLong ? `${name.slice(0, 10)}...` : name;

  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    let maxHeight = 0;
    cards.forEach((card) => {
      const cardHeight = card.clientHeight;
      if (cardHeight > maxHeight) {
        maxHeight = cardHeight;
      }
    });

    // Set all cards to the maximum height
    cards.forEach((card) => {
      (card as HTMLElement).style.height = `${maxHeight}px`;
    });
  }, []);

  return (
    <div ref={cardRef} className="card bg-[#0D0D22] text-white p-7 sm:p-6 rounded-[20px] shadow-lg max-w-xs mx-auto sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2 mb-5">
        <img
          className="w-10 h-12 sm:w-16 sm:h-16 md:w-16 md:h-16 rounded-full"
          src={image}
          alt={name}
        />
        <div className="text-center sm:text-left">
          <h2
            className="text-lg sm:text-xl md:text-[20px] font-[700] text-[#D1D1E3]"
            title={isNameLong ? name : ""}
          >
            {displayName}
          </h2>
          <p className="text-sm sm:text-base md:text-[16px] font-normal text-[#8B8B99]">
            {designation}
          </p>
        </div>
      </div>
      <h3 className="text-base text-[#D1D1E3] sm:text-lg md:text-[16px] lg:text-[16px] font-[700] mb-2">
        {title}
      </h3>
      <p className="text-[#8B8B99] text-sm sm:text-base md:text-[16px] font-normal md:mt-6 md:mb-4">
        {description}
      </p>
    </div>
  );
};

export default Card;