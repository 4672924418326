import { LOCAL_STORAGE_DATA_KEYS } from "../Utils/Constants/localStorageDataModels"

export const setDataOnLocalStorage = (key: string, data: any) => {
    try {
        // Check if data is not already a string
        const valueToStore = typeof data === 'string' ? data : JSON.stringify(data);
        localStorage.setItem(key, valueToStore);
    } catch (error) {
        console.error(`Error storing data to local storage for key ${key}:`, error);
    }
};


export const getDataFromLocalStorage = <T>(key: string): T | string | null => {
    const value = localStorage.getItem(key);
    if (value === null) {
        return null;  
    }
    try {
        return JSON.parse(value) as T;
    } catch (error) {
        return value;  
    }
};


export const removeDataFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}

export const isMobile = (): boolean => {
    if (window.screen.width <= 992) {
        return true;
    }
    return false;
}

/**
     * @param object - Object of objects
     * @returns - Array of objects
     */

export const getArrayOfObjects = (object: any): Array<any> => {
    if (object) {
        return Object.keys(object).map(key => {
            return object[key];
        })
    } else {
        return [];
    }
}