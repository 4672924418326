import { IoReload } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import noIssue from "../../../../../Assets/Svg/NoIssue.svg";
const Empty = () => {
  const navigate = useNavigate();
  const handleReload = () => {
    navigate(0);
  };
  return (
    <div className="flex flex-col justify-center items-center h-full text-[#B2B2C1] text-center">
      <img src={noIssue} alt="noissueImage" />
      <div className="mt-4">
        <h1 className="text-[#D1D1E3] text-[14px] font-semibold">
          No Issues Logged
        </h1>
        <p className="text-[#B2B2C1] text-[12px] mx-5 mt-1">
          All clear! No issues have been reported in your code.
        </p>
      </div>
      <div className="my-3">
        <button
          className="text-[#875BF8] border border-[#875BF8] text-[14px] py-2 px-4 rounded-lg flex items-center space-x-2"
          onClick={handleReload}
        >
          <IoReload className="text-[#875BF8]" />
          <span>Refresh Logs</span>
        </button>
      </div>
    </div>
  );
};

export default Empty;
