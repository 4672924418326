import React, { useState, useEffect, useRef } from "react";
import { Search, ChevronDown, ChevronUp } from "lucide-react";
import { IoSearchOutline } from "react-icons/io5";
import axios from "axios";
import Spinner from "../../../../Common/Spinner";

interface Option {
  id: string;
  name: string;
}

interface CustomDropdownProps<T extends Option> {
  options: T[];
  selectedValue: string;
  onChange: (value: string) => void;
  isLoading: boolean;
  placeholder: string;
  label: string;
  buttonText?: string;
}

function CustomDropdown<T extends Option>({
  options,
  selectedValue,
  onChange,
  placeholder,
  label,
  buttonText,
  isLoading,
}: CustomDropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("sessionId");

  const handleConnectWithGithub = async () => {
    const apiURL = `${process.env.REACT_APP_API_URL}bucket=account&operationName=gitAppInstallationUrl&carrier=github`;
    setLoading(true);
    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: token,
        },
      });
      const githubAccessUrl = response.data.data.url;
      window.location.href = githubAccessUrl;
    } catch (error) {
      console.error("Error initiating GitHub login:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="relative w-full mb-3" ref={dropdownRef}>
      <h1 className="text-[#E2E2ED] text-[14px] mb-2">{label}</h1>
      <div
        className="bg-[#0D0D22] border border-[#2E2E60] rounded-md p-2 flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-[#E2E2ED]">
          {selectedValue
            ? options.find((opt) => opt.id === selectedValue)?.name
            : placeholder}
        </span>
        {isOpen ? (
          <ChevronUp className="text-[#E2E2ED]" />
        ) : (
          <ChevronDown className="text-[#E2E2ED]" />
        )}
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-[#0D0D22] border border-[#2E2E60] rounded-md shadow-lg">
          <div className="p-2 border-b border-[#28293D] flex items-center">
            <IoSearchOutline className="text-[#E2E2ED] mr-2 text-[22px]" />
            <input
              ref={inputRef}
              type="text"
              placeholder={`Search ${label.toLowerCase()}`}
              className="w-full bg-transparent text-[#E2E2ED] outline-none"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {isLoading ? (
            <Spinner w={3} h={3} />
          ) : (
            <ul className="max-h-24 overflow-auto bg-[#131330] min-scrollbar">
              {filteredOptions.map((option) => (
                <li
                  key={option.id}
                  className="px-4 py-2 hover:bg-[#2E2E60] cursor-pointer text-[#E2E2ED] rounded-[5px]"
                  onClick={() => handleSelect(option.id)}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          )}
          {buttonText && (
            <div className="p-2 border-t border-[#28293D]">
              <button
                className="w-full bg-[#7829F9] text-white py-2 rounded-md hover:bg-[#6B24E0]"
                onClick={(e) => {
                  e.stopPropagation();
                  handleConnectWithGithub();
                }}
              >
                {loading ? (
                  <div className="flex items-center justify-center w-full h-6">
                    <div className="">
                      <Spinner w={1} h={1} />
                    </div>
                  </div>
                ) : (
                  buttonText
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
