import React, { useState, useEffect } from "react";
import configureBg from "../../../../../Assets/Svg/configureBg.svg";
import axios from "axios";
import CustomDropdown from "./CustomDropdown";
import { toast } from "react-toastify";
import { getDataFromLocalStorage } from "../../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../../../Utils/Constants/localStorageDataModels";

// API URL constant
const API_URL = process.env.REACT_APP_API_URL;

// Types
type Repo = { id: string; name: string };
// type Branch = { name: string };
type Language = { id: string; name: string };
interface SetupProps {
  onProceed: (data: {
    repoName: string;
    branchName: string;
    language: string;
    languageId: string;
    repoId: string;
    dsnURL: string;
    platform: string;
  }) => void;
  onBack: () => void;
}
interface InstallSDKProps {
  repoName: string;
  branchName: string;
  language: string;
  languageId: string;
  dsnURL: string;
  platform: string | object;
}
const SetUP: React.FC<SetupProps> = ({ onProceed, onBack }) => {
  const [repos, setRepos] = useState<Repo[]>([]);
  const [branches, setBranches] = useState<string[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedRepo, setSelectedRepo] = useState<Repo | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";

  useEffect(() => {
    fetchRepos();
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (selectedRepo) {
      fetchBranches(selectedRepo.name);
    }
  }, [selectedRepo]);

  const fetchRepos = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${API_URL}bucket=pulse&operationName=listRepositories&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setRepos(response.data.data);
    } catch (err) {
      toast.error("Failed to fetch repositories");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBranches = async (repoName: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${API_URL}bucket=pulse&operationName=listBranches&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        { repo_name: repoName },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setBranches(response.data.data); // This should now be an array of strings
    } catch (err) {
      toast.error("Failed to fetch branches");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLanguages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${API_URL}bucket=pulse&operationName=listLanguages&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setLanguages(response.data.data);
    } catch (err) {
      toast.error("Failed to fetch languages");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRepoDDChange = (repoId: string) => {
    const repo = repos.find((r) => r.id == repoId);
    if (repo) {
      setSelectedRepo(repo);
      setSelectedBranch("");
      setSelectedLanguage(null);
    } else {
    }
  };

  const handleBranchDDChange = (branchName: string) => {
    setSelectedBranch(branchName);
  };

  const handleLanguageDDChange = (languageId: string) => {
    const language = languages.find((l) => l.id === languageId);
    if (language) {
      setSelectedLanguage(language);
    }
  };

  const handleProceed = async () => {
    if (!selectedRepo || !selectedBranch || !selectedLanguage) return;

    setIsLoading(true);
    setError(null);
    try {
      const data = {
        repo_name: selectedRepo.name,
        branch_name: selectedBranch,
        language_id: Number(selectedLanguage.id),
        repo_id: selectedRepo.id,
      };

      const createMonitoringResponse = await axios.post(
        `${API_URL}bucket=pulse&operationName=createMonitoring&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        data,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      if (createMonitoringResponse.data.message == "ok") {
        const connectIdResponse = await axios.get(
          `${API_URL}bucket=pulse&operationName=getConnectId&orgName=${encodeURIComponent(
            selectedOrg
          )}&project_id=${createMonitoringResponse.data.data}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        onProceed({
          repoName: selectedRepo.name,
          branchName: selectedBranch,
          language: selectedLanguage.name,
          languageId: selectedLanguage.id,
          repoId: selectedRepo.id,
          dsnURL: connectIdResponse.data.data.dsn,
          platform: connectIdResponse.data.data.platform,
        });
      }
    } catch (err) {
      setError("Failed to process the request");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`bg-[#0D0D22] shadow-md rounded-3xl mt-3 p-8 relative  border border-[#2E2E60]`}
    >
      <div className="mb-4">
        <h2 className=" text-[#D1D1E3] font-bold text-[22px]">
          Configure CodePulse
        </h2>
        <p className="text-[#9494A1] text-[14px] font-normal">
          Choose the repositories you want CodePulse to monitor.
        </p>
        <img
          src={configureBg}
          className="absolute top-0 right-6 w-[6rem] h-[6rem]"
        />
      </div>
      {error && <div className="error">{error}</div>}

      <CustomDropdown
        options={repos}
        selectedValue={selectedRepo?.id || ""}
        onChange={handleRepoDDChange}
        isLoading={isLoading}
        placeholder="Select repository"
        label="Select repository"
        buttonText="Add New Repository"
      />

      {selectedRepo ? (
        <CustomDropdown
          options={branches.map((branch) => ({ id: branch, name: branch }))}
          selectedValue={selectedBranch}
          onChange={handleBranchDDChange}
          placeholder="Select a branch"
          label="Branch"
          isLoading={isLoading}
        />
      ) : (
        <p className="text-[14px] font-normal text-[#9494A1] my-4">
          No Branch available{" "}
        </p>
      )}

      <div className="mb-5">
        <h2 className="text-[16px] font-semibold text-[#E2E2ED] mb-2">
          Select Your Programming Language
        </h2>
        <p className="text-[14px] font-normal text-[#9494A1] mb-4">
          Choose the programming language for which you want to integrate
          CodePulse
        </p>
        <div className="flex flex-wrap justify-start bg-[#131330] p-1 rounded-xl">
          {languages.map((language) => (
            <button
              key={language.id}
              onClick={() => handleLanguageDDChange(language.id)}
              value={selectedLanguage?.id || ""}
              className={`m-1 px-3 py-2 text-[14px] font-normal rounded-[8px] ${
                language?.id === selectedLanguage?.id
                  ? "bg-[#2E2E60] border-2 border-[#875BF8] text-[#E7E7EA]"
                  : "bg-[#131330] text-[#E7E7EA]"
              } hover:bg-[#2E2E60] hover:text-[#FFFFFF] transition-colors duration-200`}
            >
              {language.name}
            </button>
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-3 mt-2">
        <button
          onClick={handleProceed}
          disabled={
            !selectedRepo || !selectedBranch || !selectedLanguage || isLoading
          }
          className={`text-[#FFFFFF] text-[14px] font-normal rounded-[8px] p-2 px-5 ${
            !selectedRepo || !selectedBranch || !selectedLanguage || isLoading
              ? "bg-[#a583fb] cursor-not-allowed"
              : "bg-[#875BF8] cursor-pointer"
          }`}
        >
          Proceed
        </button>

        <button
          onClick={onBack}
          className="bg-[#0D0D22] rounded-xl border border-[#875BF8] text-[#875BF8] text-[14px] font-normal p-2 px-5 cursor-pointer"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SetUP;
