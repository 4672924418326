import React from 'react';
import EyeScan from "../../Assets/Svg/EyeScan.svg"
import Targets from "../../Assets/Svg/Target.svg"
import HeartHand from "../../Assets/Svg/HandHeart.svg"

const AboutUs = () => {
  return (
    <div className="text-white w-full px-6 md:px-12 lg:px-32 py-4 flex flex-col items-center justify-center mt-10">
      <div className="mb-12 w-full max-w-8xl">
        <h2 className="text-[#875BF8] font-semibold text-start mb-2 text-[20px]">About us</h2>
        <h1 className="text-3xl md:text-4xl lg:text-5xl text-start font-bold ml-2 text-[#D1D1E3]">Join us on a journey of Innovation</h1>
        {/*<p className="mt-4 text-start text-[#8B8B99] mx-auto font-normal">
          Sed id lacus eu nibh molestie imperdiet sed vitae elit. Sed viverra rutrum velit vestibulum sollicitudin.
          Duis pretium rutrum tellus, a congue urna cursus ut. Sed malesuada aliquam est, at fringilla tortor vulputate
          ac. Proin in laoreet neque. Etiam placerat rutrum mauris, sit amet dictum nisi gravida fringilla. Nullam
          laoreet nulla quis ex vehicula fringilla. Integer fringilla.Sed viverra rutrum velit vestibulum sollicitudin.
          Duis pretium rutrum tellus, a congue urna cursus ut. Sed malesuada aliquam est, at fringilla tortor vulputate
          ac. Proin in laoreet neque. Etiam placerat rutrum mauris, sit amet dictum nisi gravida fringilla. Nullam
          laoreet nulla quis ex vehicula fringilla. Integer fringilla,
        </p>*/}
      </div>
      <div className="flex flex-col md:flex-row justify-around gap-12 w-full max-w-full">
        <div className="flex flex-col items-center md:items-start md:w-1/3 text-center md:text-left">
          <div className="mb-4">
            <img src={EyeScan} alt="Our Vision" className="w-20 h-20 mx-auto md:mx-0" />
          </div>
          <h3 className="text-lg font-semibold text-[#D1D1E3]">Our Vision</h3>
          <p className="mt-2 text-[#8B8B99] break-words max-w-xs">
            To simplify technology and make it usable and accessible to everyone in the world.
          </p>
        </div>
        <div className="flex flex-col items-center md:items-start md:w-1/3 text-center md:text-left">
          <div className="mb-4">
            <img src={Targets} alt="Our Mission" className="w-20 h-20 mx-auto md:mx-0" />
          </div>
          <h3 className="text-lg font-semibold text-[#D1D1E3]">Our Mission</h3>
          <p className="mt-2 text-[#8B8B99] break-words max-w-xs">
            To help users build, grow, and scale by adapting to scalable and affordable technology.
          </p>
        </div>
        <div className="flex flex-col items-center md:items-start md:w-1/3 text-center md:text-left">
          <div className="mb-4">
            <img src={HeartHand} alt="Our Values" className="w-20 h-20 mx-auto md:mx-0" />
          </div>
          <h3 className="text-lg font-semibold text-[#D1D1E3]">Our Values</h3>
          <p className="mt-2 text-[#8B8B99] break-words max-w-xs">
            We're obsessed with ownership, integrity, and agility.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;