import React, { useEffect, useState } from "react";
import Headers from "../../Components/Common/Headers";
import Home from "../../Components/Home/Home";
import DescriptionSection from "../../Components/Home/DescriptionSection";
import AboutUs from "../../Components/Home/AboutUs";
import Review from "../../Components/Home/Review";
import ContactUs from "../../Components/Home/ContactUs";
import Footer from "../../Components/Common/Footer";
import WhyUs from "../../Components/Home/WhyUs";
import "./index.css";
import { useNavigate } from "react-router-dom";
import {
  getDataFromLocalStorage,
  setDataOnLocalStorage,
} from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";
// import { sanitizeUserData, useUser } from "../../UserContext";

const Homepage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const { setUserData } = useUser();

  // useEffect(() => {
  //   const storedAccessToken = getDataFromLocalStorage(
  //     LOCAL_STORAGE_DATA_KEYS.ACCESS_TOKEN
  //   );
  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);
  //   const codeParam = urlParams.get("code");

  //   const fetchUserDetails = async (token: string) => {
  //     try {
  //       const response = await fetch(
  //         "https://api.drcode.ai/api/auth/getUserDetails",
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       const sanitizedData = sanitizeUserData(data);
  //       setUserData(sanitizedData);
  //     } catch (error) {
  //       console.error("Error fetching user details:", error);
  //     }
  //   };

  //   const fetchAccessTokenAndUserDetails = async (code: string) => {
  //     try {
  //       const response = await fetch(
  //         `https://api.drcode.ai/api/auth/getUserGithubAccessToken?code=${code}`,
  //         {
  //           method: "GET",
  //         }
  //       );
  //       const data = await response.json();
  //       if (data.accessToken) {
  //         setDataOnLocalStorage(
  //           LOCAL_STORAGE_DATA_KEYS.ACCESS_TOKEN,
  //           data.accessToken
  //         );
  //         await fetchUserDetails(data.accessToken);
  //         navigate("/dashboard/home"); // Redirect to dashboard
  //       }
  //     } catch (error) {
  //       console.error("Error fetching access token:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   if (codeParam && !storedAccessToken) {
  //     setIsLoading(true);
  //     fetchAccessTokenAndUserDetails(codeParam);
  //   } else if (storedAccessToken) {
  //     fetchUserDetails(storedAccessToken);
  //   }
  // }, [navigate, setUserData]);

  if (isLoading) {
    return <h1>Redirecting...</h1>;
  }

  return (
    <div className="home min-h-screen text-white p-3 w-full max-w-[2050px] mx-auto">
      <Headers />
      <Home />
      <WhyUs />
      <DescriptionSection />
      <Review />
      <AboutUs />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Homepage;
