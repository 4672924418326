import React from "react";

type ChipProps = {
  imageSrc: string;
  mainText: string;
  subText: string;
};

const Chip: React.FC<ChipProps> = ({ imageSrc, mainText, subText }) => {
  const roundedValue = Math.round(Number(subText));
  return (
    <div className="w-full flex items-center  px-2 py-4 rounded-lg shadow-md bg-[#0D0D22] border-[#1B1B41] border">
      {/* Left side image */}
      <div className="w-8 h-8">
        <img
          src={imageSrc}
          alt={mainText}
          className="w-full h-full object-cover rounded-full"
        />
      </div>
      {/* Right side text */}
      <div className="ml-2">
        <div className=" text-gray-500   text-sm text-nowrap">{mainText}</div>
        <div className="text-white  font-bold mt-1 text-[15px]">
          {roundedValue}
        </div>
      </div>
    </div>
  );
};

export default Chip;
