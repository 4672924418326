import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  heading: string;
  content: string;
  primaryButton: string;
  secondaryButton: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm, heading, content, primaryButton, secondaryButton }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-[#06060D] opacity-95" onClick={onClose}></div>
      <div className="bg-[#0D0D22] rounded-2xl shadow-lg p-6 w-80 z-10">
        <h2 className="text-lg font-semibold mb-2 text-white">{heading}</h2>
        <p className="mb-4 text-white">{content}</p><br/>
        <div className="flex justify-end">
          <button onClick={onConfirm} className="bg-[#0D0D22] rounded-xl border border-[#875BF8] text-[#875BF8] font-base py-2 px-6 mr-2">
            {secondaryButton}
          </button>
          <button onClick={onClose} className="bg-[#875BF8] text-[#FFFFFF] rounded-xl font-base py-2 px-6" >
            {primaryButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;