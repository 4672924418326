import React, { FC } from 'react';
import SyntaxHighlighter from "react-syntax-highlighter";

// Define the type for each context code item
interface ContextCodeItem {
  module: string;
  filename: string;
  function: string;
  inApp: boolean;
  absPath: string;
  colNo: number;
  lineNo: number;
  context: [number, string][]; // Each context is an array with a line number and code string
}

// Define the type for the props
interface MarkdownRendererProps {
  contextCode: ContextCodeItem[];
}

const customStyle = {
  hljs: {
    color: "#e6e6e6",
    background: "#0D0D22", // Background color
  },
  "hljs-keyword": {
    color: "#875BF8", // Color for keywords
    fontWeight: "bold",
  },
  "hljs-string": {
    color: "#0B7B69", // Color for strings
  },
  "hljs-comment": {
    color: "#D1D1E3", // Color for comments
  },
  "hljs-built_in": {
    color: "#8be9fd", // Color for built-in functions
  },
  "hljs-function": {
    color: "#ffff", // Color for function names
  },
  "hljs-variable": {
    color: "#F4C790", // Color for variables
  },
  "hljs-number": {
    color: "#F4C790", // Color for numbers (red)
  },
  // Add more custom styles here if needed
};

const MarkdownConverter: FC<MarkdownRendererProps> = ({ contextCode }) => {
  return (
    <div style={{ backgroundColor: '#0D0D22', padding: '10px', borderRadius: '8px', border: '1px solid #1B1B41' }}>
      {contextCode.map((frame, frameIndex) => (
        <div key={frameIndex} style={{ marginBottom: '10px' }}>
          {frame.context.map(([lineNumber, code]) => (
            <div key={lineNumber} style={{ marginBottom: '5px' }} className='flex items-center space-x-2'>
              <span style={{ color: '#888', marginRight: '10px' }} className='text-[14px]'>{lineNumber}</span>
              <SyntaxHighlighter style={customStyle} className='text-[#B2B2C1] text-[14px]'>{code}</SyntaxHighlighter>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MarkdownConverter;