import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
  KeyboardEvent,
} from "react";

import axios from "axios";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import { LOCAL_STORAGE_DATA_KEYS } from "../../../../Utils/Constants/localStorageDataModels";
import { getDataFromLocalStorage } from "../../../../Utils/globalUtilities";
import MarkdownPreview from "@uiw/react-markdown-preview";
import "./Chat.css";
import { IoCopyOutline } from "react-icons/io5";
import { TfiReload } from "react-icons/tfi";
import { toast } from "react-toastify";
import submitButton from "../../../../Assets/Svg/submitButton.svg";
import CodePulseLogo from "../../../../Assets/Svg/codepulseLogo.svg";
import userLogo from "../../../../Assets/Svg/userLogo.svg";
import ChatPrompt from "./ChatPrompt";
import AnimationLoader from "../../../Common/AnimationLoader";
import FormattedDisplay from "./FormattedDisplay";

import AutoResizeTextarea from "./AutoResizeTextarea";
const API_URL = process.env.REACT_APP_API_URL;

interface Message {
  id: number;
  sender: "user" | "drcode";
  text: string;
}

interface ChatProps {
  threadId: string;
  onUpdateThreadList: (newThread: { threadid: string; title: string }) => void;
  setSelectedThreadId: (newId: string) => void;
  isCreatingNew: boolean;
  setIsCreatingNew: (value: boolean) => void;
  notNew: boolean;
  messages: Message[]; // Update to accept an array of Message
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const Chat: React.FC<ChatProps> = ({
  threadId,
  onUpdateThreadList,
  setSelectedThreadId,
  isCreatingNew,
  setIsCreatingNew,
  notNew,
  messages,
  setMessages,
}) => {
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";
  // console.log("indpendent messagae", messages);
  useEffect(() => {
    if (isCreatingNew && !notNew) {
      // console.log("from createion  true and not new false");
      if (threadId) {
        fetchConversation();
      }
    }
  }, [isCreatingNew]);

  useEffect(() => {
    if (notNew && !isCreatingNew && threadId) {
      // console.log("from notnew  true and isCreatingNew false");
      fetchConversation();
    }
  }, [threadId, notNew]);

  // useEffect(() => {
  //   if (notNew == false && isCreatingNew == false) {
  //     setMessages([]);
  //   }
  // }, [notNew, isCreatingNew]);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchConversation = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_URL}bucket=chat&operationName=conversation&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        { threadId },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      const formattedMessages: Message[] = response.data.data.messages.map(
        (msg: any, index: number) => ({
          id: index,
          sender: msg.user ? "user" : "drcode",
          text: msg.user || msg.drcode || "",
        })
      );
      setMessages(formattedMessages);
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e?: React.FormEvent, message?: string) => {
    e?.preventDefault();

    const submittedMessage = message || inputMessage;

    if (!submittedMessage.trim() || isLoading) {
      return;
    }

    const userMessage: Message = {
      id: messages.length,
      sender: "user",
      text: submittedMessage,
    };

    const loadingMessage: Message = {
      id: messages.length + 1,
      sender: "drcode",
      text: "Hold tight, brilliance is on its way...",
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      loadingMessage,
    ]);
    setInputMessage("");
    setIsLoading(true);

    try {
      let newThreadId = threadId;
      if (isCreatingNew || !threadId) {
        const newThreadResponse = await axios.get(
          `${API_URL}bucket=chat&operationName=newThread&orgName=${encodeURIComponent(
            selectedOrg
          )}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        newThreadId = newThreadResponse.data.data.threadId;
        setSelectedThreadId(newThreadId);
        setIsCreatingNew(false);
      }

      const response = await fetch(
        `${API_URL}bucket=chat&operationName=ask&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            threadId: newThreadId,
            query: userMessage.text,
          }),
        }
      );

      if (!response.body) throw new Error("No response body");

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      let accumulatedResponse = "";
      let isFirstChunk = true;

      while (!done) {
        const { done: readerDone, value } = await reader.read();
        done = readerDone;

        const chunk = decoder.decode(value, { stream: true });
        const cleanChunk = chunk
          .split("\n")
          .map((line) => line.replace(/^data: /, "").trim())
          .join("\n");

        accumulatedResponse += cleanChunk;
        if (isFirstChunk) {
          // Replace "Typing..." with the updated accumulated response
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              id: prevMessages.length - 1,
              sender: "drcode",
              text: accumulatedResponse, // Pass the full accumulated response here
            },
          ]);
          isFirstChunk = false;
        } else {
          // console.log("chat from codewithai ", accumulatedResponse);
          // Update the last message with accumulated response
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              id: prevMessages.length - 1,
              sender: "drcode",
              text: accumulatedResponse,
            },
          ]);
        }
      }

      onUpdateThreadList({ threadid: newThreadId, title: userMessage.text });
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          id: prevMessages.length - 1,
          sender: "drcode",
          text: "An error occurred while fetching the response.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied!");
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow new line on Shift+Enter
        return;
      } else {
        // Submit on Enter without Shift
        e.preventDefault();
        handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
      }
    }
  };
  const handleRegenerate = async (messageId: number) => {
    const messageToRegenerate = messages.find((msg) => msg.id === messageId);
    if (!messageToRegenerate || messageToRegenerate.sender !== "drcode") return;

    let userMessage;
    if (messageId === 0) {
      userMessage = {
        text: "Analyze the issue properly and give me with potentially different wording, examples, or additional insights.",
      };
    } else {
      const userMessageIndex = messages.findIndex(
        (msg) => msg.id === messageId - 1
      );
      if (userMessageIndex === -1) return;
      userMessage = messages[userMessageIndex];
    }

    setIsLoading(true);
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId
          ? { ...msg, text: "Regenerating response..." }
          : msg
      )
    );

    try {
      const response = await fetch(
        `${API_URL}bucket=chat&operationName=ask&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            threadId,
            query: userMessage.text,
          }),
        }
      );

      if (!response.body) throw new Error("No response body");

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      let accumulatedResponse = "";

      while (!done) {
        const { done: readerDone, value } = await reader.read();
        done = readerDone;

        const chunk = decoder.decode(value, { stream: true });
        const cleanChunk = chunk
          .split("\n")
          .map((line) => line.replace(/^data: /, "").trim())
          .join("\n");

        accumulatedResponse += cleanChunk;

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === messageId ? { ...msg, text: accumulatedResponse } : msg
          )
        );
      }
    } catch (error) {
      console.error("Error regenerating message:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId
            ? {
                ...msg,
                text: "An error occurred while regenerating the response.",
              }
            : msg
        )
      );
    } finally {
      setIsLoading(false);
    }
  };
  // border-r border-t
  return (
    <div className="w-[75%] bg-[#1B1B41] border  border-[#2E2E60] flex flex-col h-full rounded-r-lg">
      {loading ? (
        <AnimationLoader />
      ) : isCreatingNew || !threadId ? (
        <ChatPrompt
          setInputMessage={setInputMessage}
          setSelectedThreadId={setSelectedThreadId}
          setIsCreatingNew={setIsCreatingNew}
          onUpdateThreadList={onUpdateThreadList}
          handleSubmit={handleSubmit}
        />
      ) : (
        <>
          <div
            ref={chatContainerRef}
            className="flex-grow overflow-y-auto mb-4 px-2 space-y-4 mt-2 codepluse-scrollbar"
          >
            {messages.map((message) => (
              <div
                key={message.id}
                className={`mb-4 flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div className={`max-w-[80%]`}>
                  <div
                    className={`flex items-center space-x-2 mb-1 ${
                      message.sender === "user"
                        ? "flex-row-reverse"
                        : "flex-row"
                    }`}
                  >
                    <img
                      src={message.sender === "user" ? userLogo : CodePulseLogo}
                      alt={message.sender === "user" ? "User" : "DrCode"}
                      className={`w-4 h-4 ${
                        message.sender === "user" && "ml-2"
                      }`}
                    />
                    <div className="text-[12px] font-inter text-[#777781]">
                      {message.sender === "user" ? "You" : "DrCode"}
                    </div>
                  </div>
                  <div
                    className={`p-2 rounded-lg   text-[14px] ${
                      message.sender === "user"
                        ? "text-left text-[#E2E2ED]"
                        : "text-left bg-[#0A0A1A] text-[#D1D1E3]"
                    }`}
                  >
                    {message.sender === "drcode" ? (
                      <MarkdownPreview
                        className="custom-markdown"
                        source={message.text}
                      />
                    ) : (
                      <FormattedDisplay message={message} />
                    )}
                  </div>
                  {message.sender === "drcode" && (
                    <div className="w-14 flex justify-start bg-[#06060D] gap-4 px-2 py-3 mt-2 rounded-lg">
                      <button
                        onClick={() => handleCopy(message.text)}
                        className="text-white"
                      >
                        <IoCopyOutline size={12} />
                      </button>
                      <button
                        onClick={() => handleRegenerate(message.id)}
                        className="text-white"
                      >
                        <TfiReload size={12} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <form
            onSubmit={handleSubmit}
            className="px-4 z-10 flex justify-between items-end pb-5"
          >
            <AutoResizeTextarea
              value={inputMessage}
              onKeyDown={handleKeyDown}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type your message..."
              isInitialLoading={isLoading}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default Chat;
