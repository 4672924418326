interface Organization {
  id: string;
  org_name: string;
  avatar_url: string;
}

export interface UserData {
  user_id: string;
  username: string;
  name: string;
  carrier: string;
  orgs: Organization[];
}

export const LOCAL_STORAGE_DATA_KEYS = {
  ACCESS_TOKEN: "accessToken",
  USER_DATA: "userData",
  SELECTED_ORG: "selectedOrg",
  SESSION_ID: "sessionId"
};
