import noData from "../../../../Assets/Svg/reviewChatNoData.svg";
const ChartEmpty = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-center">
      <img src={noData} alt="" />
      <h1 className="text-[#9494A1] text-[14px] font-medium mt-2">No Data</h1>
    </div>
  );
};

export default ChartEmpty;
