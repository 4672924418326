import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
interface ChartData {
  name: string;
  data: number[];
}

type StrokeType =
  | "smooth"
  | "straight"
  | "stepline"
  | "linestep"
  | "monotoneCubic";

interface SplineChartProps {
  data: Array<{
    name: string;
    data: number[];
  }>;
  colors: string[];
  stroke: StrokeType[]; // Updated this line
  labels: string[];
}

const SplineChart: React.FC<SplineChartProps> = ({
  data,
  colors,
  stroke,
  labels,
}) => {
  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: "#777781",
        },
      },
      axisBorder: {
        color: "#2E2E60",
      },
      axisTicks: {
        color: "#2E2E60",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#777781",
        },
      },
    },
    colors: colors,
    stroke: {
      curve: stroke,
      width: 3,
    },
    legend: {
      show: false, // Hide legends
    },
    grid: {
      borderColor: "#2E2E60",
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const date = w.globals.categoryLabels[dataPointIndex];
        return `
            <div style="
       padding: 10px;
        background: #1B1B41;
        color: white;
        border-radius: 5px;
        min-width: 150px;
        text-align: left;
        border: 0; 
            ">
              <div style="font-weight: semibold; margin-bottom: 5px;">${date}</div>
              ${series
                .map(
                  (s: number[], i: number) => `
                <div style="display: flex; align-items: center; margin: 3px 0;">
                  <div style="
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: ${colors[i]};
                    margin-right: 5px;
                  "></div>
                  <span>${s[dataPointIndex]}</span>
                </div>
              `
                )
                .join("")}
            </div>
          `;
      },
    },
  };

  return (
    <div className="spline-chart">
      <ReactApexChart
        options={options}
        series={data}
        type="line"
        height={350}
      />
    </div>
  );
};

export default SplineChart;
