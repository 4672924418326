import React, { useState } from "react";
import Logo from "../../Assets/Svg/Logo.svg";
import { useNavigate } from "react-router-dom";
import "../../App.css";

const Headers: React.FC = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleNavigate = () => {
    navigate("/login");
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="w-[95%] z-50 max-w-[1200px] mx-auto">
      <div className="flex justify-between items-center py-2 bg-blue bg-opacity-15 backdrop-blur-lg">
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="DrCode.ai"
            className="h-32 w-32"
            style={{ verticalAlign: 'middle' }} // Add inline style for fine-tuning
          />
          {/*<span className="text-2xl font-bold text-white font-spaceGrotesk">DrCode AI</span>*/}
        </div>
        <div className="sm:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {/* <nav className="hidden sm:flex sm:flex-grow justify-center space-x-6">
          <p className="text-[#8B8B99] hover:text-[#875BF8] font-medium font-inter text-lg transition duration-300 cursor-pointer">
            Pricing
          </p>
          <p className="text-[#8B8B99] hover:text-[#875BF8] text-lg font-medium font-inter transition duration-300 cursor-pointer">
            Blog
          </p>
          <p className="text-[#8B8B99] hover:text-[#875BF8] text-lg font-medium font-inter transition duration-300 cursor-pointer">
            About us
          </p>
        </nav> */}
        <div className="flex items-center space-x-4">
          <button
            onClick={handleNavigate}
            className="hidden bg-[#875BF8] sm:block ml-0 sm:ml-6 border text-purple-600 border-purple-600 hover:border-purple-700 rounded-lg text-white font-base py-2 px-8 transition"
          >
            <p className="text-white text-base">Login</p>
          </button>
          <button
            onClick={handleNavigate}
            className="hidden bg-[#875BF8] sm:block ml-0 sm:ml-6 border text-purple-600 border-purple-600 hover:border-purple-700 rounded-lg text-white font-base py-2 px-8 transition"
          >
            <p className="text-white text-base">Get started</p>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="sm:hidden flex flex-col items-center bg-white bg-opacity-15 backdrop-blur-lg p-4 space-y-4">
          {/* <p className="text-slate-400 hover:text-purple-300 font-medium text-lg transition duration-300">
            Pricing
          </p>
          <p className="text-slate-400 hover:text-purple-300 text-lg font-medium transition duration-300">
            Blog
          </p>
          <p className="text-slate-400 hover:text-purple-300 text-lg font-medium transition duration-300">
            About us
          </p> */}
          <button
            onClick={handleNavigate}
            className="border-2 text-purple-600 border-purple-600 hover:border-purple-700 rounded-xl text-white font-bold py-2 px-4 transition"
          >
            <p className="text-violet-400 text-base">Get started</p>
          </button>
        </div>
      )}
    </header>
  );
};

export default Headers;
