import { useState } from "react";
import SetUP from "./Components/SetUP";
import { useNavigate } from "react-router-dom";
import InstallSDK from "./Components/InstallSdk";

interface InstallSDKProps {
  repoName: string;
  branchName: string;
  language: string;
  languageId: string;
  dsnURL: string;
  platform: string | object;
}

// ConfigScreen Component
const ConfigScreen: React.FC = () => {
  const [step, setStep] = useState<"setup" | "install">("setup");
  const [installData, setInstallData] = useState<InstallSDKProps | null>(null);
  const navigate = useNavigate();

  const handleProceed = (data: {
    repoName: string;
    branchName: string;
    language: string;
    languageId: string;
    repoId: string;
    dsnURL: string;
    platform: string;
  }) => {
    setInstallData({
      repoName: data.repoName,
      branchName: data.branchName,
      language: data.language,
      languageId: data.languageId,
      dsnURL: data.dsnURL,
      platform: data.platform,
    });
    setStep("install");
  };

  const handleBack = () => {
    if (step === "install") {
      setStep("setup");
    } else {
      navigate("/pulse");
    }
  };

  return (
    <div className="relative flex justify-center items-center w-full h-screen bg-[#080814] overflow-hidden">
      <div
        className={`w-full h-full flex justify-center items-center transition-transform duration-300 ${
          step === "setup" ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <SetUP onProceed={handleProceed} onBack={handleBack} />
      </div>
      <div
        className={`absolute w-full h-full flex justify-center items-center  transition-transform duration-300 ${
          step === "install" ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {installData && <InstallSDK {...installData} onBack={handleBack} />}
      </div>
    </div>
  );
};

export default ConfigScreen;
