import React from "react";
import Carousel from "../../@/Carousel/Carousel";

const Review = () => {
  return (
    <div className="w-full px-6 py-8">
      <div className="bg-[#11112C] shadow-md rounded-lg mt-6 py-8 px-5 mx-auto ">
        <h1 className="text-5xl font-bold text-center text-[#D1D1E3] mb-3 mt-6">Checkout Recent Reviews</h1>
        <Carousel />
      </div>
    </div>
  );
};

export default Review;
