import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Pages/Layout";
import PublicRoute from "./Components/ScopeRoute/PublicRoute";
import PrivateRoute from "./Components/ScopeRoute/PrivateRoute";
import Homepage from "./Pages/HomePage";
import Login from "./Components/Login/Login";
import NotFound from "./Components/Common/NotFound";
import HomeTab from "./Components/Dasboard/HomeTab";
import WelcomeScreen from "./Components/Dasboard/CodePulse/WelcomeScreen/WelcomeScreen";
import ConfigureScreen from "./Components/Dasboard/CodePulse/ConfigureScreen/ConfigureScreen";

import {
  LAYOUT,
  HOME,
  LOGIN,
  NOT_FOUND,
  CODE_REVIEW,
  CHAT_SUPPORT,
  CODE_PULSE_CONFIGURE,
  CODE_PULSE,
  SETTINGS,
  CODE_PULSE_REPOSITORIES,
  CODE_PULSE_REPOSITORY_LOG_LIST,
  CALLBACK,
  DASHBOARD,
} from "./Utils/Constants/RouteConstant";
import { ToastContainer } from "react-toastify";
// import CodePulse from "./Components/Dasboard/CodePulse/CodePulse";
import RepositoryLogList from "./Components/Dasboard/CodePulse/RepositoryLogList/RepositoryLogList";
import Repositories from "./Components/Dasboard/CodePulse/Repositories/components/Repositories";
import RedirectPage from "./Components/Common/RedirectPage";
import CodeReview from "./Components/Dasboard/CodeReview/CodeReview";
import ChatWithAI from "./Components/Dasboard/ChatWithAI/ChatWithAI";

function App() {
  return (
    <>
      <div className="font-inter">
        <BrowserRouter>
          <Routes>
            <Route
              path={HOME}
              element={
                <PublicRoute>
                  <Homepage />
                </PublicRoute>
              }
            />
            <Route
              path={CALLBACK}
              element={
                <PublicRoute>
                  <RedirectPage />
                </PublicRoute>
              }
            />
            <Route
              path={LOGIN}
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />

            <Route
              path={LAYOUT}
              element={
                <PrivateRoute>
                  <Layout />
                </PrivateRoute>
              }
            >
              <Route path={DASHBOARD} element={<HomeTab />} />
              <Route path={CODE_REVIEW} element={<CodeReview />} />
              <Route path={CODE_PULSE} element={<WelcomeScreen />} />

              <Route
                path={CODE_PULSE_CONFIGURE}
                element={<ConfigureScreen />}
              />
              {/* <Route path={CODE_PULSE} element={<CodePulse />} /> */}
              <Route path={CHAT_SUPPORT} element={<ChatWithAI />} />

              <Route path={SETTINGS} element={<WelcomeScreen />} />
              <Route path={CODE_PULSE_REPOSITORY_LOG_LIST} element={<RepositoryLogList />} />
            </Route>

            <Route path={NOT_FOUND} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={2}
          // toastClassName={'theme-toast'}
        />
      </div>
    </>
  );
}

export default App;
