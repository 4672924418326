import React from 'react'
import notFound from "../../Assets/Svg/404page.svg";
import { useNavigate } from 'react-router-dom';
import { DASHBOARD, HOME } from '../../Utils/Constants/RouteConstant';
const NotFound = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("access_token") !== null;
  const handleCancel = () => {
    if (isLoggedIn) {
      return navigate(`${DASHBOARD}`)
    }
    navigate(`${HOME}`)
  }
  return (
    <div className='flex justify-end items-center pr-20' style={{ background: `url(${notFound})`, objectFit: "contain", height: "100vh", width: "100%" }}>
      <div className='flex justify-center items-center h-full w-[60%] flex-col'>
        <p className='flex justify-center items-center h-auto w-full text-[70px] text-white font-semibold'>Page Not Found!</p><br />
        <p className='text-white text-2xl'>Error 404</p><br />
        <p className='text-white text-center text-2xl justify-center items-center pr-10'>
          The link you clicked may be broken or the page may have been renamed or removed.
        </p>
        <br />
        <div className="flex justify-center">
          <button className="flex w-[148px] justify-center items-center self-stretch rounded-lg border border-solid border-white h-12">
            <div
              className="text-white text-center font-[Poppins] text-[13px] font-medium leading-[19.5px] capitalize"
              onClick={handleCancel}
            >
              Go Back
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFound