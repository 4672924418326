import React, { useState } from "react";
import Github from "../../Assets/Svg/Github.svg";
import { toast } from 'react-toastify';

interface ApplicationFormType {
  full_name: string;
  contact_number: number | null;
  email: string;
  subject: string;
  message: string;
}

const Home: React.FC = () => {
  const [email, setEmail] = useState("");

  const sendEmailHandler = async () => {
    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }

    const apiURL = "https://api.airiasearch.com/api/pre_login/contact_us";
  
    let reqobj = {
      full_name: "Dr.Code",
      contact_number: 1234567890,
      email: email,
      subject: "Dr.Code Enquiry",
      message: "Someone just clicked on enquire now button!"
    };
  
    try {
      // Make a request to the pre_login endpoint
      const response = await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(reqobj)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Parse the response as JSON
      const responseData = await response.json();
  
      // Assuming the response has a message field
      const responseMessage = responseData.message;
  
      // Display the message as a toast notification
      toast.success(responseMessage);

      setEmail("");
    } catch (error) {
      console.error("Error initiating GitHub login:", error);
    }
  };


  return (
    <main className="flex flex-col items-center justify-center text-center text-white pt-12 lg:p-12">
      <div className="flex flex-col md:flex-row items-start justify-evenly w-full mt-8">
        <div className=" md:text-left md:mr-2 md:mt-10 lg:mr-0">
          <h1 className="text-[2.75rem] font-bold mb-6 text-[#D1D1E3] leading-tight ">
            Enhance your <br /> code reviews with <br />
            <span className="bg-gradient-to-b from-[#A380FF] via-[#9975F4] to-[#290781] bg-clip-text text-transparent">
                AI precision
            </span>
          </h1>
          <p className="text-lg mb-8 text-[#8B8B99] font-normal">
            Reduce your PR merge time from days to hours with <br className="hidden md:block" /> intelligent code analysis, bug identification and fixes
          </p>
          <div className="relative lg:w-[520px] md:w-[410px] sm:w-[300px] md:w-auto flex items-center bg-[#0A0A1F] p-1 rounded-lg">
          <input
            type="email"
            placeholder="Enter your work email"
            className="p-4 w-full md:w-full rounded-lg focus:outline-none bg-[#0A0A1F] text-white placeholder-[#8B8B99]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={sendEmailHandler}
          className="absolute text-lg font-lg right-1 top-1 bottom-1 bg-gradient-to-b from-[#A380FF] via-[#9975F4] to-[#290781] text-white px-7 py-2 rounded-lg hover:from-[#9A34DF] hover:to-[#553BD4] focus:outline-none">
            Enquire Now!
          </button>
        </div>
        </div>

        <div className="relative  mt-12 md:mt-0 md:ml-2">
          <img
            src={Github}
            alt="Code review screenshot"
            className="w-full h-auto"
          />
        </div>
      </div>
    </main>
  );
};

export default Home;
