import React, { useState, useEffect, useRef } from "react";
import Card from "../Card/Card";

const Carousel = () => {
  const cards = [
    {
      image:
        "https://cdn.drcode.ai/assets/app-images/testimonials/anuj.webp",
      name: "Anuj Shah",
      description:
        "It's Live Tracking feature is amazing! It tracks production bugs and reports instantly. Much needed feature.",
      designation: "Tech Lead",
    },
    {
      image:
        "https://cdn.drcode.ai/assets/app-images/testimonials/ismail.webp",
      name: "Ismail Khan",
      description:
        "DrCode is easy to install. It automatically finds issues in the code and suggests fixes line by line. It's an absolute productivity booster!",
      designation: "Lead Software Engineer",
    },
    {
      image:
        "https://cdn.drcode.ai/assets/app-images/testimonials/shubham.webp",
      name: "Shubham Dhabhai",
      description:
        "Personally, I love the sequence diagrams which helps in documentation and understanding the PR properly.",
      designation: "SDE III",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isLaptop = screenSize >= 1024;
  const itemsPerPage = isLaptop ? 3 : 1;
  const numberOfPages = Math.ceil(cards.length / itemsPerPage);

  useEffect(() => {
    startAutoPlay();
    return () => stopAutoPlay();
  }, [currentIndex, numberOfPages]);

  const startAutoPlay = () => {
    stopAutoPlay();
    intervalRef.current = setInterval(() => {
      goToNextSlide();
    }, 3000);
  };

  const stopAutoPlay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const goToPreviousSlide = () => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? numberOfPages - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) =>
      prevIndex === numberOfPages - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  return (
    <div className="relative w-full p-10 mx-auto">
      <div className="overflow-hidden relative">
        <div
          className={`flex transition-transform duration-500 ease-in-out ${
            isTransitioning ? "transform" : ""
          }`}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          onTransitionEnd={handleTransitionEnd}
        >
          {isLaptop
            ? Array.from({ length: numberOfPages }, (_, i) => (
                <div className="w-full flex-shrink-0 flex" key={i}>
                  {cards.slice(i * 3, i * 3 + 3).map((card, index) => (
                    <div className="w-1/3 px-6 rounded-lg" key={index}>
                      <Card
                        image={card.image}
                        name={card.name}
                        title=''
                        description={card.description}
                        designation={card.designation}
                      />
                    </div>
                  ))}
                </div>
              ))
            : cards.map((card, index) => (
                <div className="w-full flex-shrink-0 px-4" key={index}>
                  <Card
                    image={card.image}
                    name={card.name}
                    title=''
                    description={card.description}
                    designation={card.designation}
                  />
                </div>
              ))}
        </div>
      </div>
      <div className="absolute mt-2 bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {Array.from({ length: numberOfPages }, (_, i) => (
          <span
            key={i}
            className={`w-8 h-1 rounded-full ${
              i === currentIndex ? "bg-[#875BF8]" : "bg-[#62627C]"
            } cursor-pointer`}
            onClick={() => setCurrentIndex(i)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;