import React from "react";
import { Navigate } from "react-router-dom";
import { DASHBOARD } from "../../Utils/Constants/RouteConstant";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";
import { getDataFromLocalStorage } from "../../Utils/globalUtilities";


const PublicRoute =({ children }: { children: JSX.Element }) => {
    const isLoggedIn = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID);
    
    if (isLoggedIn) {
        return <Navigate to={DASHBOARD} />;
    }
    return children;
};

export default PublicRoute;
