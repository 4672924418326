import axios, {
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosHeaders,
} from "axios";

export const request = (
  method: string,
  url: string,
  data?: any,
  params?: any,
  headers: Record<string, string> = {}
): Promise<any> => {
  return axios({
    method,
    url,
    data,
    params,
    headers,
  })
    .then((response: AxiosResponse) => response.data)
    .catch((error) => {
      throw handleError(error);
    });
};

// Centralized error handling function
const handleError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const status = error.response?.status;

    switch (status) {
      case 400:
        console.error("Bad Request:", error.message);
        break;
      case 401:
        console.error("Unauthorized. Redirecting to login...");
        // Redirect to login page or refresh token
        // window.location.href = '/login';
        break;
      case 403:
        console.error("Forbidden:", error.message);
        break;
      case 404:
        console.error("Resource not found:", error.message);
        break;
      case 500:
        console.error("Internal server error:", error.message);
        break;
      case 503:
        console.error("Service unavailable. Retrying...");
        // Implement retry logic
        break;
      default:
        console.error("An error occurred:", error.message);
    }
    return {
      message:
        error.response?.data?.message || "An error occurred. Please try again.",
      status,
    };
  } else {
    console.error("An unexpected error occurred:", error);
    return { message: "An unexpected error occurred. Please try again." };
  }
};

// Configure Axios interceptors
export const configureAxios = () => {
  axios.interceptors.request.use(
    (reqConfig: InternalAxiosRequestConfig) => {
      if (reqConfig.headers instanceof AxiosHeaders) {
        reqConfig.headers.set("Content-Type", "application/json");
        const token = localStorage.getItem("access_token") || "";
        if (token) {
          reqConfig.headers.set("Authorization", `Bearer ${token}`);
        }
      }

      reqConfig.baseURL = "base url";

      return reqConfig;
    },
    (error) => {
      console.error("Request error:", error);
      return Promise.reject(handleError(error));
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      return Promise.reject(handleError(error));
    }
  );
};

export const splitAndReduceText = (
  fullText: string,
  wordLimit: number
): string => {
  if (!fullText) {
    return ""; // Return an empty string or a default message
  }
  // Split the text by spaces to get individual words
  const wordsArray = fullText.split(" ");

  // Check if the wordLimit exceeds the number of words
  if (wordLimit >= wordsArray.length) {
    return fullText; // Return the full text if the limit is higher than the word count
  }

  // Slice the array to include only the first 'wordLimit' words
  const reducedTextArray = wordsArray.slice(0, wordLimit);

  // Join the reduced array back into a string and return it
  const reducedText = reducedTextArray.join(" ");

  return reducedText + "..."; // Add ellipsis to indicate truncation
};
