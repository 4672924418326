import React from "react";
import sdkBg from "../../../../../Assets/Svg/sdkBg.svg";
import copyIcon from "../../../../../Assets/Svg/copyIcon.svg";
import { toast } from "react-toastify";
import SyntaxHighlighter from "react-syntax-highlighter";
import { FaGithub } from "react-icons/fa";
import { IoIosGitBranch } from "react-icons/io";

import {
  customStyle,
  GoCmd,
  languageCodeMap,
  NodeCmd,
  PythonCmd,
  RustCmd,
} from "../command";
interface InstallSDKProps {
  repoName?: string;
  branchName?: string;
  language?: string;
  languageId?: string;
  dsnURL?: string;
  platform?: string | object;
  setShowDNSDetails?: (value: boolean) => void;
  onBack: () => void;
}

const InstallSdk: React.FC<InstallSDKProps> = ({
  repoName,
  branchName,
  language,
  dsnURL,
  languageId,
  platform,
  setShowDNSDetails,
  onBack,
}) => {
  const copyCommand = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  let installationCommand = "";

  if (language == "Node") {
    installationCommand = NodeCmd;
  } else if (language == "Python" || language == "Python 3") {
    installationCommand = PythonCmd;
  } else if (language == "Go") {
    installationCommand = GoCmd;
  } else {
    installationCommand = RustCmd;
  }

  const getSetupCode = () => {
    if (!language || !dsnURL) return "";
    if (language == "Python") {
      // alert("pythong..");
      language = "Python 3";
    }
    const codeGenerator = languageCodeMap[language];
    if (typeof codeGenerator === "function") {
      return codeGenerator({ dsnURL });
    }
    return `// Code snippet for ${language} is not available`;
  };

  const setupCode = getSetupCode();

  return (
    <div
      className={`w-[60%] bg-[#0D0D22] shadow-md rounded-3xl  p-7 relative  border border-[#2E2E60]`}
    >
      <div className="bg-[#0D0D22] shadow-md rounded-3xl  ">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-[24px] font-[700] text-left text-[#E2E2ED]">
              Install CodePulse SDK
              <div className="flex justify-start gap-2 ">
                <FaGithub className="text-lg text-[#E2E2ED] " />
                <p className="text-[14px] font-normal text-[#9494A1] mt-0">
                  {repoName}
                </p>
                <IoIosGitBranch className="text-sm text-[#E2E2ED] mt-1" />
                <p className="text-[14px] font-normal text-[#9494A1] mt-0">
                  {branchName}
                </p>
              </div>
            </h1>
          </div>
          <img
            src={sdkBg}
            className="absolute top-0 right-6 w-[6rem] h-[6rem]"
          />
        </div>
        <div className="flex flex-col items-start mt-5">
          <h1 className="text-[16px] font-semibold text-left text-[#E2E2ED]">
            Install the drcode package
            <p className="text-[14px] font-normal text-[#9494A1] mt-0">
              Install the SDK for{" "}
              <span className="font-semibold">{language}</span> and integrate
              the code snippet.
            </p>
          </h1>
          <div className="flex justify-between bg-[#0D0D22] border-2 border-[#2E2E60] rounded-[8px] text-[#B2B2C1] text-[14px] font-normal p-3 mt-4 w-full">
            {installationCommand}
            <img
              src={copyIcon}
              className="cursor-pointer"
              onClick={() => copyCommand(installationCommand)}
            />
          </div>
          <div className="relative bg-[#080814] border-2 border-[#1B1B41] text-[#B2B2C1] text-[14px] p-1 mt-4 w-full h-[200px] overflow-auto rounded-lg codepluse-scrollbar">
            <div className="">
              <SyntaxHighlighter
                language={language}
                style={customStyle}
                className="rounded-md w-full text-[12px]"
              >
                {setupCode}
              </SyntaxHighlighter>
              <img
                src={copyIcon}
                className="absolute top-3 right-3 cursor-pointer"
                onClick={() => copyCommand(setupCode)}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end space-x-3 mt-5">
          <button
            onClick={onBack}
            className="bg-[#0D0D22] rounded-xl border border-[#875BF8] text-[#875BF8] text-[14px] font-normal p-2 pl-5 pr-5 cursor-pointer"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default InstallSdk;
