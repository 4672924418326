import checkedIcon from "../../../../../Assets/Svg/checkedIcon.svg";
import uncheckedIcon from "../../../../../Assets/Svg/uncheckedIcon.svg";
import { FaAngleRight, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { IoCalendarClearOutline } from "react-icons/io5";
import { useState, useEffect } from "react";
interface SortMenuProps {
  onSortChange: (param: string) => void;
  currentSort: string;
  currentType: string;
  setIsDateUpdate: (data: boolean) => void;
  setStartDate: (date: number) => void;
  setEndDate: (date: number) => void;
  startDate: number;
  endDate: number;
}

const SortMenu: React.FC<SortMenuProps> = ({
  onSortChange,
  currentSort,
  currentType,
  setStartDate,
  setEndDate,
  setIsDateUpdate,
  startDate,
  endDate,
}) => {
  const [localStartDate, setLocalStartDate] = useState(
    startDate ? new Date(startDate * 1000).toISOString().split("T")[0] : ""
  );
  const [localEndDate, setLocalEndDate] = useState(
    endDate ? new Date(endDate * 1000).toISOString().split("T")[0] : ""
  );

  const [dateModal, setDateModal] = useState(false);
  const [isAscending, setIsAscending] = useState(true);

  const sortOptions = [
    { label: "Last Seen", value: "last_seen" },
    { label: "First Seen", value: "first_seen" },
    { label: "Most frequent", value: "count" },
    { label: "Highest priority", value: "priority" },
  ];
  const typeOptions = [
    { label: "Unresolved", value: "unresolved" },
    { label: "Resolved", value: "resolved" },
  ];

  // Helper function to convert date string to Unix timestamp
  const dateToUnixTimestamp = (
    dateString: string,
    isEndDate: boolean
  ): number => {
    if (!dateString) return 0;
    const date = new Date(dateString);
    if (isEndDate) {
      date.setHours(23, 59, 59, 999); // Set to end of day
    } else {
      date.setHours(0, 0, 0, 0); // Set to start of day
    }
    return Math.floor(date.getTime() / 1000);
  };

  const handleDateFilter = () => {
    const startTimestamp = dateToUnixTimestamp(localStartDate, false);
    const endTimestamp = dateToUnixTimestamp(localEndDate, true);

    setStartDate(startTimestamp);
    setEndDate(endTimestamp);

    handleDateModal();
    setIsDateUpdate(true);
    onSortChange(currentSort);
  };

  const handleDateModal = () => {
    setDateModal(!dateModal);
    if (!dateModal) {
      setIsDateUpdate(false);
    }
  };

  useEffect(() => {
    setIsAscending(!currentSort.startsWith("-"));
  }, [currentSort]);
  useEffect(() => {
    if (startDate) {
      setLocalStartDate(new Date(startDate * 1000).toISOString().split("T")[0]);
    }
    if (endDate) {
      setLocalEndDate(new Date(endDate * 1000).toISOString().split("T")[0]);
    }
  }, [startDate, endDate]);

  const handleOrderToggle = () => {
    const newIsAscending = !isAscending;
    setIsAscending(newIsAscending);
    const currentSortOption = sortOptions.find(
      (option) => option.value === currentSort.replace("-", "")
    );
    if (currentSortOption) {
      onSortChange(
        newIsAscending ? currentSortOption.value : `-${currentSortOption.value}`
      );
    }
  };

  const handleSortOptionClick = (optionValue: string) => {
    onSortChange(isAscending ? optionValue : `-${optionValue}`);
  };

  // In the render part:
  <button onClick={handleOrderToggle} className="text-[#B6B6BF]">
    {isAscending ? <FaSortAmountUp /> : <FaSortAmountDown />}
  </button>;

  return (
    <>
      <div className="absolute right-0 top-5 w-48 bg-[#0D0D22] border border-[#2E2E60] rounded-md shadow-lg z-10 py-2">
        <div className="px-4 py-2 flex justify-between items-center">
          <span className="text-[#B6B6BF] text-[14px]">Order</span>
          <button onClick={handleOrderToggle} className="text-[#B6B6BF]">
            {isAscending ? <FaSortAmountUp /> : <FaSortAmountDown />}
          </button>
        </div>
        <div className="mb-1">
          <h1 className="ml-2 px-2 text-[12px] text-[#B6B6BF]">Sort by</h1>
          {sortOptions.map((option) => (
            <div
              key={option.value}
              className="px-4 py-2 text-[#B2B2C1] text-[14px] hover:bg-[#131330] cursor-pointer flex items-center"
              onClick={() => handleSortOptionClick(option.value)}
            >
              <input
                type="checkbox"
                checked={currentSort.replace("-", "") === option.value}
                readOnly
                className="hidden"
                id={`custom-checkbox-${option.value}`}
              />
              <label
                htmlFor={`custom-checkbox-${option.value}`}
                className="mr-2 cursor-pointer "
              >
                <img
                  src={
                    currentSort.replace("-", "") === option.value
                      ? checkedIcon
                      : uncheckedIcon
                  }
                  alt="Checkbox"
                  className="h-4 w-4"
                />
              </label>
              {option.label}
            </div>
          ))}
        </div>
        <div className="border-t border-[#2E2E60] pt-2">
          <h1 className="ml-2  px-2 text-[12px] text-[#B6B6BF] ">by type</h1>
          {typeOptions.map((option) => (
            <div
              key={option.value}
              className="px-4 py-2 text-[#B2B2C1] text-[14px] hover:bg-[#131330] cursor-pointer flex items-center"
              onClick={() => onSortChange(option.value)}
            >
              <input
                type="checkbox"
                checked={currentType === option.value}
                readOnly
                className="hidden"
                id={`custom-checkbox-${option.value}`}
              />
              <label
                htmlFor={`custom-checkbox-${option.value}`}
                className="mr-2 cursor-pointer "
              >
                <img
                  src={
                    currentType === option.value ? checkedIcon : uncheckedIcon
                  }
                  alt="Checkbox"
                  className="h-4 w-4"
                />
              </label>
              {option.label}
            </div>
          ))}
        </div>
        <div
          className="flex justify-between items-center px-3 border-t border-[#2E2E60] pt-2"
          onClick={handleDateModal}
        >
          <h1 className="text-[#D1D1E3]  text-[14px]">Sort by date</h1>
          <FaAngleRight className="text-[#D1D1E3]" />
        </div>
      </div>
      {dateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
          <div className="bg-[#0D0D22] rounded-lg p-6 w-[35%]">
            <h1 className="text-start text-[#D1D1E3] text-[18px] mb-4 ">
              Sort by date
            </h1>
            <div className="flex  gap-4">
              <div>
                <h1 className="text-start text-[#E2E2ED] text-[13px]  font-normal">
                  Start date
                </h1>
                <div className="flex items-center border border-[#2E2E60] rounded px-2 py-1">
                  <input
                    type="date"
                    className="bg-transparent text-[#D1D1E3] focus:outline-none w-full"
                    placeholder="Start Date"
                    value={localStartDate}
                    onChange={(e) => setLocalStartDate(e.target.value)}
                  />
                  <IoCalendarClearOutline className="text-[#D1D1E3] mr-2" />
                </div>
              </div>
              <div>
                <h1 className="text-start text-[#E2E2ED] text-[13px]  font-normal">
                  End date
                </h1>
                <div className="flex items-center border border-[#2E2E60] rounded px-2 py-1">
                  <input
                    type="date"
                    className="bg-transparent text-[#D1D1E3] focus:outline-none w-full"
                    placeholder="End Date"
                    value={localEndDate}
                    onChange={(e) => setLocalEndDate(e.target.value)}
                  />
                  <IoCalendarClearOutline className="text-[#D1D1E3] mr-2" />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-5 gap-3">
              <button
                onClick={handleDateModal}
                className="px-8 py-2 border border-[#875BF8] text-[#875BF8] rounded-lg text-[14px]"
              >
                Close
              </button>
              <button
                onClick={handleDateFilter}
                className="px-8 py-2 bg-[#875BF8] text-white rounded-lg text-[14px]"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SortMenu;
