import React from "react";

interface Props {
  message: {
    text: string;
  };
}

const FormattedDisplay: React.FC<Props> = ({ message }) => {
  return (
    <pre
      style={{
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        fontFamily: "inter",
        backgroundColor: "#0D0D22",
        padding: "10px",
        // border: "1px solid #1B1B41",
        borderRadius: "4px",
        overflowX: "auto",
      }}
    >
      {message.text}
    </pre>
  );
};

export default FormattedDisplay;
