import { useEffect, useState } from "react";
import ChatInterface from "./components/ChatInterface";
import IssueList from "./components/IssueList";
import LogModal from "./components/LogModal";
// import { getSessionID, useUser } from "../../../../UserContext";
import { OrgType } from "../ConfigureScreen/types";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { projectIdToUuidMap } from "../Repositories/components/RepositoryTable";
import { getDataFromLocalStorage } from "../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../../Utils/Constants/localStorageDataModels";

// Dummy data for issues
interface Message {
  id: number;
  sender: "user" | "computer";
  text: string;
}
interface RepositoryLogList {
  className?: string;
}

const RepositoryLogList: React.FC<RepositoryLogList> = (className) => {
  const { secureId } = useParams<{ secureId: string }>();
  const location = useLocation();

  // Get project_id from location state
  const project_id = location.state?.project_id;

  const API_URL = process.env.REACT_APP_API_URL;
  const [logData, setLogData] = useState<any>(null);
  const [issueID, setIssueID] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(true);

  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";

  const getAuthHeaders = () => ({
    Authorization: `${token}`,
  });
  useEffect(() => {
    if (issueID != -1) {
      console.log("logo data comping");
      const fetchData = async () => {
        if (project_id != undefined && issueID != undefined) {
          try {
            setLoading(true); // Set loading state to true before fetching data
            const response = await axios.get(
              `${API_URL}bucket=pulse&operationName=issue&project_id=${project_id}&issue_id=${issueID}&orgName=${encodeURIComponent(
                selectedOrg
              )}`,
              { headers: getAuthHeaders() }
            );
            setLogData(response.data.data);
          } catch (error) {
            console.error("Error fetching log data:", error);
          } finally {
            setLoading(false); // Set loading state to false after fetching data
          }
        } else {
          console.log("project id is undefinedß");
        }
      };
      fetchData();
    }
  }, [issueID]);
  const [openLog, setOpenLog] = useState<boolean>(false);

  return (
    <div
      className={`flex w-full justify-center bg-[#080814] items-center h-screen ${className} `}
    >
      {openLog == false ? (
        <>
          <IssueList
            project_id={Number(project_id) || -1}
            setIssueID={setIssueID}
            issueID={issueID}
          />
          <ChatInterface
            // messages={dummyMessages}
            setOpenLog={setOpenLog}
            logData={logData}
            loading={loading}
            issueID={issueID}
            project_id={Number(project_id) || -1}
          />
        </>
      ) : (
        <LogModal
          setOpenLog={setOpenLog}
          orgName={selectedOrg}
          logData={logData}
          loading={loading}
        />
      )}
    </div>
  );
};

export default RepositoryLogList;
