import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import { FaHistory } from "react-icons/fa";

interface TimeRange {
  value: string;
  label: string;
}

const timeRanges: TimeRange[] = [
  { value: "last_30_mins", label: "Last 30 minutes" },
  { value: "last_1_hour", label: "Last 1 hour" },
  { value: "last_3_hours", label: "Last 3 hours" },
  { value: "today", label: "Today" },
  { value: "last_7_days", label: "Last 7 days" },
  { value: "last_30_days", label: "Last 30 days" },
  { value: "last_3_months", label: "Last 3 months" },
  { value: "all", label: "All time" },
  { value: "custom", label: "Custom" },
];

interface HeaderProps {
  onRangeChange: (rangeType: string, startDate?: Date, endDate?: Date) => void;
}

const Header: React.FC<HeaderProps> = ({ onRangeChange }) => {
  const [selectedRange, setSelectedRange] = useState<string>("last_7_days");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [tempStartDate, setTempStartDate] = useState<Date | null>(null);
  const [tempEndDate, setTempEndDate] = useState<Date | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectRange = (value: string) => {
    setSelectedRange(value);
    if (value !== "custom") {
      setIsOpen(false);
      onRangeChange(value);
    }
  };

  const handleDateClick = (date: Date) => {
    if (!tempStartDate || (tempStartDate && tempEndDate)) {
      setTempStartDate(date);
      setTempEndDate(null);
    } else {
      if (date < tempStartDate) {
        setTempEndDate(tempStartDate);
        setTempStartDate(date);
      } else {
        setTempEndDate(date);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const applyCustomRange = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setSelectedRange("custom");
      setIsOpen(false);
      onRangeChange("custom", tempStartDate, tempEndDate);
    }
  };
  const generateCalendar = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDayIndex = firstDay.getDay();

    const days = [];
    for (let i = 0; i < startingDayIndex; i++) {
      days.push(null);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }
    return days;
  };

  const isInRange = (date: Date) => {
    return (
      tempStartDate &&
      tempEndDate &&
      date >= tempStartDate &&
      date <= tempEndDate
    );
  };

  const formatDate = (date: Date) => {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };

  const resetSelection = () => {
    setSelectedRange("all");
    setStartDate(null);
    setEndDate(null);
    setTempStartDate(null);
    setTempEndDate(null);
  };

  const changeMonth = (increment: number) => {
    setCurrentMonth(
      new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth() + increment,
        1
      )
    );
  };

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDateStyles = (date: Date | null) => {
    if (!date) return "text-[#131330]";

    let styles = "text-[#7B8199] hover:bg-[#1E1E3F]";

    if (isInRange(date)) {
      styles = "bg-[#2E2E60] text-white";
    }

    if (
      date.getTime() === tempStartDate?.getTime() ||
      date.getTime() === tempEndDate?.getTime()
    ) {
      styles = "bg-[#875BF8] text-white rounded";
    }

    return styles;
  };

  return (
    <div className="flex justify-between items-center  px-4 py-1">
      <div>
        <h1 className="text-[#D1D1E3] font-bold text-[22px]">Code Review</h1>
        <p className="text-[#B2B2C1] text-[14px]">
        Enhance your code quality with thorough reviews, identifying issues and providing actionable insights.
        </p>
      </div>
      <div className="relative" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="bg-[#080814] text-[#D1D1E3] px-2 py-1 rounded-md flex items-center justify-between w-52  border-[#2E2E60] border text-left"
        >
          <FaHistory className="text-[22px] text-[#D1D1E3]" />
          <span className="text-start mx-2 text-[15px]  w-full">
            {selectedRange === "custom" && startDate && endDate
              ? `${formatDate(startDate)} - ${formatDate(endDate)}`
              : timeRanges.find((range) => range.value === selectedRange)
                  ?.label}
          </span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </button>
        {isOpen && (
          <div className="absolute z-10 my-2 bg-[#131330] border-[#2E2E60] border rounded-md shadow-lg right-0">
            <div className="flex">
              <div className="w-32">
                {timeRanges.map((range) => (
                  <button
                    key={range.value}
                    onClick={() => handleSelectRange(range.value)}
                    className={`block w-full text-left px-4 py-2 text-sm font-normal
                         text-[#D1D1E3] rounded-sm
                     hover:bg-[#1E1E3F] hover:rounded-lg`}
                  >
                    {range.label}
                  </button>
                ))}
                <button
                  onClick={resetSelection}
                  className="block w-full text-left px-4 py-2 text-sm text-[#875BF8] hover:bg-[#1E1E3F] hover:rounded-lg"
                >
                  Reset
                </button>
              </div>
              {selectedRange === "custom" && (
                <div className="p-4 bg-[#131330] rounded-md w-64 border-[#2E2E60] border-l">
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-[#B2B2C1] font-semibold text-[14px] ">
                      {currentMonth.toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                    <div className="gap-2">
                      <button
                        onClick={() => changeMonth(-1)}
                        className="text-[#D9D9D9]"
                      >
                        <ChevronLeft className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => changeMonth(1)}
                        className="text-[#D9D9D9]"
                      >
                        <ChevronRight className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-7 gap-1 mb-2">
                    {dayNames.map((day) => (
                      <div
                        key={day}
                        className="text-[#7B8199] text-xs font-semibold"
                      >
                        {day}
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-cols-7 gap-1">
                    {generateCalendar().map((date, index) => (
                      <button
                        key={index}
                        onClick={() => date && handleDateClick(date)}
                        disabled={!date}
                        className={`w-8 h-8 flex items-center justify-center text-sm ${getDateStyles(
                          date
                        )}`}
                      >
                        {date?.getDate()}
                      </button>
                    ))}
                  </div>
                  <div className="mt-10 flex justify-end">
                    <button
                      onClick={applyCustomRange}
                      className="bg-[#875BF8] text-white px-4 py-2 rounded-md text-sm"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
