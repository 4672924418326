import React, { useState, useRef, useEffect } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import SettingIcon from "../../Assets/Svg/SettingsIcon.svg";
import Logout from "../../Assets/Svg/Logout.svg";
import profileDefault from "../../Assets/Svg/userDefaultImage.png";
import {
  getDataFromLocalStorage,
  setDataOnLocalStorage,
} from "../../Utils/globalUtilities";
import {
  LOCAL_STORAGE_DATA_KEYS,
  UserData,
} from "../../Utils/Constants/localStorageDataModels";

interface OrgData {
  id: string;
  org_name: string;
  avatar_url: string;
}

interface ProfileProps {
  isFullSidebar: boolean;
  selectedTab: string;
  onLogout: () => void;
  onSettingsClick: () => void;
}

const Profile: React.FC<ProfileProps> = ({
  isFullSidebar,
  selectedTab,
  onLogout,
  onSettingsClick,
}) => {
  const [showOrgs, setShowOrgs] = useState(false);
  const orgsRef = useRef<HTMLDivElement | null>(null); // Ref for the orgs dropdown container

  const handleShowOrgs = () => {
    setShowOrgs(!showOrgs);
  };

  const handleOrgSelect = (orgName: string) => {
    setDataOnLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG, orgName);
    setShowOrgs(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (orgsRef.current && !orgsRef.current.contains(event.target as Node)) {
        setShowOrgs(false); // Close the dropdown if clicked outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Retrieve user data from local storage
  const storedUserData: string | UserData | null = getDataFromLocalStorage(
    LOCAL_STORAGE_DATA_KEYS.USER_DATA
  );

  // Retrieve selected organization from local storage
  const storedSelectedOrg: string | null = getDataFromLocalStorage(
    LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG
  );

  let selectedOrgAvatar = profileDefault; // Default profile image
  let organizations: OrgData[] = [];

  if (storedUserData && typeof storedUserData === "object") {
    const userData = storedUserData as UserData;
    organizations = userData.orgs || [];
    const selectedOrg = organizations.find(
      (org) => org.org_name === storedSelectedOrg
    );
    if (selectedOrg) {
      selectedOrgAvatar = selectedOrg.avatar_url || profileDefault;
    }
  }

  return (
    <div className="flex flex-col px-1" ref={orgsRef}>
      {isFullSidebar && showOrgs ? (
        <div className="border border-[#2E2E60] rounded-xl p-2">
          <h1 className="text-[#B2B2C1] text-[16px] font-normal border-b border-[#1B1B41] pb-2">
            Change organisation
          </h1>
          <div className="w-full h-[auto] overflow-auto min-scrollbar">
            {organizations.map((org, index) => (
              <div
                key={index}
                className={`flex justify-start gap-2 items-center px-1 py-1 rounded-lg cursor-pointer ${
                  storedSelectedOrg === org.org_name
                    ? "bg-[#6041B0]"
                    : "hover:bg-[#1B1B41]"
                }`}
                onClick={() => handleOrgSelect(org.org_name)}
              >
                <img
                  src={org?.avatar_url || profileDefault}
                  alt={org?.org_name}
                  className="w-8 h-8 rounded-full"
                />
                <span
                  className={`text-[16px] text-center ${
                    storedSelectedOrg === org.org_name
                      ? "text-[#FFFF]"
                      : "text-[#B2B2C1]"
                  }`}
                >
                  {org?.org_name}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <div
            onClick={onSettingsClick}
            className={`flex items-center py-2 px-4 cursor-pointer rounded-lg
              ${
                isFullSidebar ? "space-x-2 w-full" : "justify-center ml-2 mb-2"
              }  
              ${
                selectedTab === "settings"
                  ? "bg-[#24243D]"
                  : "hover:bg-[#24243D]"
              }
            `}
          >
            <img src={SettingIcon} alt="setting" className="w-6 h-6" />
            {isFullSidebar && (
              <span className="text-[#62627C] mb-0 mt-0">Settings</span>
            )}
          </div>
          {isFullSidebar && (
            <img
              src={Logout}
              onClick={onLogout}
              alt="Logout"
              className="w-8 h-8 cursor-pointer"
            />
          )}
        </div>
      )}

      <div
        className={`w-full my-2 flex ${
          isFullSidebar ? "flex-col" : "flex-col justify-center"
        }`}
        onClick={handleShowOrgs}
      >
        {isFullSidebar && (
          <>
            <div className="flex justify-center mb-4">
              <hr className="bg-[#131330] h-[2px] w-[90%] border-0" />
            </div>
            <div className="w-full flex justify-between items-center mb-5 px-3">
              <div className="flex items-center space-x-2">
                <img
                  src={selectedOrgAvatar}
                  alt="Organization"
                  className="w-10 h-10 rounded-full"
                />
                <span className="text-sm">{storedSelectedOrg}</span>
              </div>
              <div className="flex flex-col items-center">
                <FaChevronUp
                  className={`text-[10px] cursor-pointer ${
                    showOrgs ? "text-[#D1D1E3]" : "text-[#B2B2C1]"
                  }`}
                />
                <FaChevronDown
                  className={`text-[10px] cursor-pointer ${
                    !showOrgs ? "text-[#D1D1E3]" : "text-[#B2B2C1]"
                  }`}
                />
              </div>
            </div>
          </>
        )}
        {!isFullSidebar && (
          <div className="flex flex-col items-center">
            <img
              src={selectedOrgAvatar}
              alt="Organization"
              className="w-10 h-10 rounded-full"
            />
            <img
              src={Logout}
              onClick={onLogout}
              alt="Logout"
              className="w-8 h-8 cursor-pointer my-5"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
