import React, { useEffect, useState } from "react";
import axios from "axios";
import Chip from "./Chip";
import imag1 from "../../../../../Assets/Svg/ResolvedIssue.svg";
import imag2 from "../../../../../Assets/Svg/Issue.svg";
import imag3 from "../../../../../Assets/Svg/avgTime.svg";
import imag4 from "../../../../../Assets/Svg/uresolveIssue.svg";

const ChipList: React.FC = () => {
  // const { userData } = useUser();
  const selectedOrg = localStorage.getItem("selectedOrg") as string;
  const token = localStorage.getItem("sessionId");
  const [chipsData, setChipsData] = useState([
    {
      imageSrc: imag4,
      mainText: "Unresolved issues",
      subText: "0",
    },
    {
      imageSrc: imag1,
      mainText: "Resolved issues",
      subText: "0",
    },
    {
      imageSrc: imag3,
      mainText: "Average issue resolution time",
      subText: "0",
    },
    {
      imageSrc: imag2,
      mainText: "Critical issue",
      subText: "0",
    },
  ]);
  // const selectedOrg = userData?.selectedOrg?.org_name || "No organization";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiURL = `${
          process.env.REACT_APP_API_URL
        }bucket=pulse&operationName=stats&orgName=${encodeURIComponent(
          selectedOrg
        )}`;
        // const token = getSessionID();
        const response = await axios.get(apiURL, {
          headers: {
            Authorization: `${token}`,
          },
        });
        const { data } = response.data;
        // console.log("chitp data ", data);
        setChipsData([
          {
            imageSrc: imag4,
            mainText: "Unresolved issues",
            subText: data.unresolved_issues.toString(),
          },
          {
            imageSrc: imag1,
            mainText: "Resolved issues",
            subText: data.resolved_issues.toString(),
          },
          {
            imageSrc: imag3,
            mainText: "Average issue resolution time",
            subText: data.airt.toString(),
          },
          {
            imageSrc: imag2,
            mainText: "Critical issue",
            subText: data.critical_issues.toString(),
          },
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="">
        <h1 className="text-[#D1D1E3] font-semibold text-2xl">Code Pulse</h1>
        <p className="text-[#B2B2C1] text-[14px]">
          Get a comprehensive overview of your repository health and monitor
          errors effectively.
        </p>
      </div>
      <div className="flex justify-between items-center gap-4 mt-4">
        {chipsData.map((chip, index) => (
          <Chip
            key={index}
            imageSrc={chip.imageSrc}
            mainText={chip.mainText}
            subText={chip.subText}
          />
        ))}
      </div>
    </>
  );
};

export default ChipList;
