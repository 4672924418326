import React from "react";
import GithubDescription from "../../Assets/Svg/github_Description.svg";
import ClickUp from "../../Assets/Svg/clickup_new.svg";
import Jira from "../../Assets/Svg/jiraContainer.svg";
import WalkThrough from "../../Assets/Svg/WalkTrough.svg";
import cursor from "../../Assets/Svg/Ellipse.svg";
import AI_chat_support from "../../Assets/Svg/AI_chat_support.svg";
import pulse from "../../Assets/Svg/pulse.svg";
import code_pulse_bg from "../../Assets/Svg/code_pulse_bg.svg";
import code_pulse_front from "../../Assets/Svg/code_pulse_front.svg";
import jira_clickup_frame from "../../Assets/Svg/jira_clickup_frame.svg";
import "./WhyUs.css"


const WhyUs = () => {
  return (
    <div className=" text-white md:p-1 mb-2 md:p-3">
      <div className="w-full px-6 py-8">
        <p className="text-[20px] text-center text-[#875BF8] font-semibold">
          Why Us
        </p>
        <h1 className="text-4xl lg:text-6xl font-bold text-center text-[#D1D1E3]">
          Why choose DrCode for <br className="hidden md:block" /> your GitHub code reviews?
          <p className="text-[18px] text-[#8B8B99] mt-3 font-normal leading-tight">
            Efficiency redefined:  Secure automated solution with seamless integration to your system
            <br className="hidden md:block" /> to save time and improve code quality.
          </p>
        </h1>
      </div>
      <div className="mx-auto">
        <div className="grid w-full md:grid-cols-3">
          {/* Left Section */}
          <div className="md:col-span-2 flex flex-col justify-center items-center md:items-start mt-4 md:mt-0">
            <div className="flex-1 w-full px-3 py-4 hover:transform hover:scale-105 transition-transform duration-300">
              <div className="bg-[#11112C] shadow-md rounded-3xl pb-0 pt-4 px-10 h-full hover-gradient-bg">
                <h2 className="text-[28px] md:text-[32px] font-bold mb-4 text-left text-[#D1D1E3]">
                  Improve code quality with AI driven insights <br className="hidden md:block" /> to find bugs fast and fix faster
                </h2>
                <p className="text-base md:text-base text-[#8B8B99] mb-4">
                  Insightful code analysis with sequence diagrams, file-specific reports, and actionable fixes.
                </p>
                <div className="flex justify-center">
                  <img
                    src={GithubDescription}
                    alt="Code Fix Example"
                    className="w-full max-h-48 object-cover object-top rounded-lg "
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="md:col-span-1 flex flex-col justify-center items-center md:items-start mt-8 md:mt-0">
            <div className="flex-1 w-full px-3 md:px-4 py-4 hover:transform hover:scale-105 transition-transform duration-300">
              <div className="frame bg-[#11112C] shadow-md rounded-3xl  backdrop-blur-lg pb-10 pt-4 px-9 h-full frame-hover ">
                <h2 className="text-[25px] md:text-[27px] font-bold mb-4 text-left md:text-left text-[#D1D1E3]">
                  Seamless integration with project management tools to review PRs based on tasks
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="grid w-full md:grid-cols-3">
          {/* Left Section */}
          <div className="md:col-span-1 flex flex-col justify-center items-center md:items-start mt-4 md:mt-0">
            <div className="flex-1 w-full px-3 py-4 hover:transform hover:scale-105 transition-transform duration-300">
              <div className="shadow-md rounded-3xl mt-4 bg-[#11112C] pb-0 pt-4 px-7 h-full hover-gradient-bg">
                <h2 className="text-[25px] md:text-[29px] font-bold mb-4 text-left text-[#D1D1E3]">
                  Chat instantly
                </h2>
                  <p className="text-base md:text-base mb-4 text-[#8B8B99]">
                    Real time contextual chat with DrCode to clarify doubts about the code review and fix suggestions.
                  </p>
                  <div className="flex justify-end pr-10 mt-6 mb-6">
                  <img
                    src={AI_chat_support}
                    alt="AI chat"
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="md:col-span-2 flex flex-col justify-center items-center md:items-start mt-8 md:mt-0">
            <div className="flex-1 w-full px-6 md:px-4 py-4 hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
              <div className="bg-[#11112C] shadow-md rounded-3xl mt-4 pt-4 h-full hover-gradient-bg">
                <h2 className="text-[25px] md:text-[29px] font-bold mb-4 text-left text-[#D1D1E3] px-7">
                  CodePulse: Production Tracking Tool
                </h2>
                <p className="text-base md:text-base mb-4 text-[#8B8B99] px-7">
                  Find production issues instantly with fix suggestions leading to minimised downtime.
                </p>
                <div className="relative mt-10 ">
                  <img
                    src={code_pulse_bg}
                    alt="Background"
                    className="inset-0 w-full h-full"
                  />
                  <img
                    src={pulse}
                    alt="Pulse"
                    className="absolute top-0 left-0 object-cover"
                  />
                  <img
                    src={code_pulse_front}
                    alt="Front"
                    className="absolute top-0 h-[90px] md:h-full left-[180px] md:left-[200px] lg:left-[400px] object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyUs;

{/* <div className='tw-mt-10'>
                  <img src={ClickUp} alt="ClickUp" className="w-22 h-22 ml-[235px] mt-[-25px] md:mt-[-9px] md:ml-[90px] lg:w-16 lg:h-16 fixed lg:right-[2rem] lg:bottom-[8rem] z-10" />
                  <img src={Jira} alt="Jira" className="w-21 h-21 ml-[130px] mt-[40px] md:ml-[-5px] md:mt-[75px] lg:w-17 lg:h-17 fixed lg:right-[7.5rem] lg:bottom-[1.5rem] z-10 w-[100px]"/>
                  <img src={cursor} alt="curve" className="flex md:bottom-0 ml-[140px] md:ml-[37px] lg:ml-[120px] xl:ml-[230px] md:right-0 z-0 lg:h-[170px] xl:h-[204px]"/>
                </div> */}

          //       <div className="md:col-span-1 flex flex-col justify-center items-center md:items-start mt-8 md:mt-0">
          //   <div className="flex-1 w-full px-3 md:px-4 py-4 hover:transform hover:scale-105 transition-transform duration-300">
          //     <div className="bg-[#11112C] shadow-md rounded-3xl  backdrop-blur-lg pb-0 pt-4 px-9 h-full hover-gradient-bg">
          //       <h2 className="text-[25px] md:text-[27px] font-bold mb-4 text-left md:text-left text-[#D1D1E3]">
          //         Seamless integration with project management tools to review PRs based on tasks
          //       </h2>
          //     </div>
          //   </div>
          // </div>