import React from "react";
import Chip from "../../CodePulse/Repositories/components/Chip";
import imag1 from "../../../../Assets/Svg/FileReview.svg";
import imag2 from "../../../../Assets/Svg/pullReview.svg";
import imag3 from "../../../../Assets/Svg/ChatBotReview.svg";
import imag4 from "../../../../Assets/Svg/HashtagReview.svg";

interface TopCardsData {
  prs_reviewed: number;
  review_count: number;
  files_reviewed: number;
  suggestions_accepted: number;
}

interface ReviewChipsProps {
  topCards: TopCardsData | null;
}

const ReviewChips: React.FC<ReviewChipsProps> = ({ topCards }) => {
  const chipsData = [
    {
      imageSrc: imag2,
      mainText: "Pull requests reviewed",
      subText: topCards?.prs_reviewed?.toString() ?? "0",
    },
    {
      imageSrc: imag4,
      mainText: "Number of reviews",
      subText: topCards?.review_count?.toString() ?? "0",
    },
    {
      imageSrc: imag1,
      mainText: "Files reviewed",
      subText: topCards?.files_reviewed?.toString() ?? "0",
    },
    {
      imageSrc: imag3,
      mainText: "AI Suggestions Accepted",
      subText: topCards?.suggestions_accepted?.toFixed(2) ?? "0",
    },
  ];

  return (
    <div className="flex justify-between items-center gap-4 mt-4">
      {chipsData.map((chip, index) => (
        <Chip
          key={index}
          imageSrc={chip.imageSrc}
          mainText={chip.mainText}
          subText={chip.subText}
        />
      ))}
    </div>
  );
};

export default ReviewChips;
