import React from "react";

import { Navigate } from "react-router-dom";
import { HOME } from "../../Utils/Constants/RouteConstant";
import { getDataFromLocalStorage } from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";

const PrivateRoute =({ children }: { children: JSX.Element }) => {
  const isLoggedIn = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID);

  if (!isLoggedIn) {
    return <Navigate to={HOME}/>;
  }
  return children;
};

export default PrivateRoute;