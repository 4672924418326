import React from "react";

interface LogDataProps {
  img?: string;
  title?: string;
  Subtitle1?: string;
  numbervalue1?: string;
  Subtitle2?: string;
  numbervalue2?: string;
  compnent?: string | number;
  data?: Array<{ subtitle: string; value: string }>;
}

const LogData: React.FC<LogDataProps> = ({
  img,
  title,
  Subtitle1,
  Subtitle2,
  numbervalue1,
  numbervalue2,
  compnent,
  data,
}) => {
  console.log("tracke ", numbervalue2);
  return (
    <>
      {compnent == 1 ? (
        <div className="bg-[#1B1B41] p-3 w-[48%] rounded-lg">
          <div className=" flex flex-wrap gap-5 border-b border-[#2E2E60] pb-2">
            <img src={img} alt={title} className="w-5 h-5" />
            <h1 className="text-[#D1D1E3] font-semibold text-[14px]">
              {title}
            </h1>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="p-2">
              <h1 className="text-[#D1D1E3] font-semibold text-[14px]">
                h1 {Subtitle1}
              </h1>
              <p className="text-[#B2B2C1] font-normal text-[14px]">
                {numbervalue1}
              </p>
            </div>
            <div className="p-2 flex flex-col justify-end overflow-auto no-scrollbar">
              <h1 className="text-[#D1D1E3] font-semibold text-[14px]">
                {Subtitle2}
              </h1>
              <p className="text-[#B2B2C1] font-normal text-[14px]  ">
                {numbervalue2}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-[#1B1B41] p-3 w-[48%] h-[320px] rounded-lg">
          <div className="flex gap-5 border-b border-[#2E2E60] pb-2">
            <img src={img} alt={title} className="w-5 h-5" />
            <h1 className="text-[#D1D1E3] font-semibold text-[14px]">
              {title}
            </h1>
          </div>
          <div className="flex flex-wrap mt-2">
            {data?.map((item, index) => (
              <div key={index} className="p-2 w-1/2">
                <h1 className="text-[#D1D1E3] font-semibold text-[14px]">
                  {item.subtitle}
                </h1>
                <p className="text-[#B2B2C1] font-normal text-[14px] ">
                  {item.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LogData;
